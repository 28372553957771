import React from 'react';

import {
  DatePicker,
  DropDownSelect,
  NumberField,
  SearchSelect
} from '../FormFields';

import { genderOptions, gradeOptions, raceOptions } from './options';

const FindStep1 = () => {
  return (
    <div>
      <DatePicker label="1. What is your date of birth?" name="dateBirth" />
      <DropDownSelect
        label="2. What is your gender?"
        name="gender"
        options={genderOptions}
      />
      <SearchSelect
        label="3. What is your race?"
        name="race"
        options={raceOptions}
        multi
      />
      <NumberField label="4. What is your GPA?" name="gpa" min={0} max={4} />
      <DropDownSelect
        label="5. What is your grade level?"
        name="gradeLevel"
        options={gradeOptions}
      />
    </div>
  );
};

export default FindStep1;
