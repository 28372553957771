import React, { useState } from 'react';
import { Container } from 'shards-react';
import styled from 'styled-components';

const Form = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-header {
    font-size: 2.9rem;
    color: #1264de;
    font-weight: bold;
  }

  .form-body {
    font-size: 1.5rem;
    margin-left: 0.5rem;
    line-height: 1.5;
  }

  .text-box {
    width: auto;
  }
  @media (max-width: 1000px) {
    padding: 0 3rem 0 3rem;
    max-width: 100%;
    text-align: center;
  }
`;

const FeedbackForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    name: '',
    email: '',
    answer1: '',
    answer2: '',
    answer3: ''
  });
  const { name, email, answer1, answer2, answer3 } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        'https://v1.nocodeapi.com/arronz99/google_sheets/DoglDehsDJlALlll?tabId=Sheet1',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify([
            [
              name,
              email,
              answer1,
              answer2,
              answer3,
              new Date().toLocaleString()
            ]
          ])
        }
      );
      await response.json();
      setData({
        ...data,
        name: '',
        email: '',
        answer1: '',
        answer2: '',
        answer3: ''
      });
      setSubmitted(true);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    }
  };

  return (
    <Form>
      {submitted ? (
        <>
          <h1>Form submitted!</h1>
          <h4>Thank you for your feedback!</h4>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <h3 className="form-header">Feedback Form</h3>
          <div className="form-body">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="text-box"
              name="name"
              autoComplete="off"
              value={name}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="form-body">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="text-box"
              name="email"
              autoComplete="off"
              value={email}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="form-body">
            <label htmlFor="answer1">
              Please Rate our &quot;File&quot; (fafsa) module from 1-5 on
              &quot;usability&quot;, &quot;design&quot;, and
              &quot;usefulness&quot;. Feel free to provide any other feedback to
              help us improve!
            </label>
            <textarea
              className="text-box"
              name="answer1"
              rows="5"
              value={answer1}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="form-body">
            <label htmlFor="answer2">
              Please Rate our &quot;Find&quot; (scholarships) module from 1-5 on
              &quot;usability&quot;, &quot;design&quot;, and
              &quot;usefulness&quot;. Feel free to provide any other feedback to
              help us improve!
            </label>
            <textarea
              className="text-box"
              name="answer2"
              rows="5"
              value={answer2}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="form-body">
            <label htmlFor="answer3">
              Please Rate our &quot;Fund&quot; (housing) module from 1-5 on
              &quot;usability&quot;, &quot;design&quot;, and
              &quot;usefulness&quot;. Feel free to provide any other feedback to
              help us improve!
            </label>
            <textarea
              className="text-box"
              name="answer3"
              rows="5"
              value={answer3}
              onChange={handleChange}
            />
          </div>
          <br />
          <div className="submit">
            <input type="submit" value="Submit" className="submit" />
          </div>
          <br />
        </form>
      )}
    </Form>
  );
};

export default FeedbackForm;
