import React from 'react'
import styles from '../../styles/dashboard/featured-house.module.css'

export default function FeaturedHouse({
  address,
  photo,
  price,
  beds,
  baths,
  sqft,
  contact,
  description
}) {
  const loggedIn = false
  return (
    <section className={styles.house_card}>
      <div className={styles.house_image}>
        <img src={photo} alt="house image" />
      </div>
      <div className={styles.detail_section}>
        <h3 className={styles.address}>{address}</h3>
        <h4 className={styles.beds_baths}>
          {beds} beds | {baths} baths{sqft != null ? ` | ${sqft} sqft` : ''}
        </h4>
        <p className={styles.description}>{description}</p>
        <h4 className={styles.price}>${price.toLocaleString('en-US')}/mo</h4>
        {loggedIn ? (
          <span className={styles.contact}>{contact}</span>
        ) : (
          <span className={styles.contact}>Login to contact agent</span>
        )}
      </div>
    </section>
  )
}
