import React from 'react';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';

const FormFieldError = ({ name }) => {
  return <StyledErrorMessage component="div" name={name} />;
};

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
`;

export default FormFieldError;
