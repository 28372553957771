import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import FieldFormError from './FormFieldError';

// fieldType prop set to "Dollar" will render out a dollar amount field
// while any other string reverts it to the standard number field
const NumberField = ({ label, name, min, max, step }) => {
  return (
    <StyledNumberField>
      <label>{label}</label>
      <StyledNumberInput
        component="input"
        name={name}
        type="number"
        min={min}
        max={max}
        step={step}
      />
      <FieldFormError name={name} />
    </StyledNumberField>
  );
};

const StyledNumberField = styled.div`
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledNumberInput = styled(Field)`
  height: 3rem;
  border: solid 2px #dee2e6;
`;

export default NumberField;
