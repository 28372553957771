import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from '../../../styles/housing/housing-map.module.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import mapboxgl from '!mapbox-gl'

// mapbox setup

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
const HousingMap = ({ rentals, clickDetail }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [lng, setLng] = useState(-90.1994)
  const [lat, setLat] = useState(38.627)
  const [zoom, setZoom] = useState(11)

  const [renderedMarkers, setRenderedMarkers] = useState(false)

  useEffect(() => {
    if (!map.current) {
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom
      })
      map.current.addControl(new mapboxgl.NavigationControl())
    }

    if (!renderedMarkers && map.current && rentals.length > 0) {
      setRenderedMarkers(true)
      // add markers
      rentals.forEach((rental) => {
        if (rental.lng !== undefined && rental.lat !== undefined) {
          const markerElement = document.createElement('div')
          markerElement.className = styles.marker

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
          })

          // When hovering over a marker, show the popup
          markerElement.addEventListener('mouseover', (e) => {
            let description = `<strong>${rental.price_min}$/mo</strong><br/>${rental.beds_max} bed<br/>${rental.baths_max} bath<br/>${rental.SquareFeet} sqft`
            description = description.replaceAll(/<br\/>(undefined|0) \w*/g, '')

            popup
              .setLngLat([rental.lng, rental.lat])
              .setHTML(description)
              .addTo(map.current)
          })

          // When leaving a marker, hide the popup
          markerElement.addEventListener('mouseout', () => {
            popup.remove()
          })

          const marker = new mapboxgl.Marker(markerElement)
            .setLngLat([rental.lng, rental.lat])
            .addTo(map.current)

          // When clicking a marker, go to the details page
          marker.getElement().addEventListener('click', () => {
            clickDetail(rental.ListingId)
          })
        }
      })
    }
  })
  return (
    <section id={styles.housing_map_section}>
      <div ref={mapContainer} className={styles.map_container}></div>
    </section>
  )
}

export default HousingMap
