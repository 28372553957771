import React, { useState } from 'react'
import styles from '../../styles/housing/housing-search.module.css'
import { useHistory } from 'react-router-dom'
import searchIcon from '../../assets/housing/search-icon.svg'

import PartnerSection from '../../components/housing/search/PartnerSection'

import lemonadePromo from '../../assets/housing/partner-assets/lemonade-promo.png'
import loanPromo from '../../assets/housing/partner-assets/zero-percent.png'
import experianPromo from '../../assets/housing/partner-assets/experian-promo.png'

const HousingSearch = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')

  const handleChange = (event) => {
    setSearch(event.target.value)
    console.log(event.target.value)
  }

  const handleSubmit = (event) => {
    history.push(`/housing/${search}`)
    event.preventDefault()
  }

  return (
    <main>
      <section id={styles.search_hero}>
        <h1>Your Housing Search Begins Here</h1>
        <form onSubmit={handleSubmit}>
          <div id={styles.search_bar}>
            <input
              placeholder="Enter a city, college, or ZIP code"
              value={search}
              onChange={handleChange}
              name="search"
              autoComplete="off"
              required
            />
            <button type="submit">
              <img src={searchIcon} alt="search icon" />
            </button>
          </div>
        </form>
      </section>
      <section>
        <h2 id={styles.partner_header}>
          Moving to a new home is complex
          <br />
          We can help with that
        </h2>
        <PartnerSection
          img={lemonadePromo}
          name="lemonade promo"
          header="1. Renters' Insurance"
          description="Renters' Insurance is a must-have when moving to a new home."
          link="https://imp.i146998.net/5bqGJN"
          active={true}
        ></PartnerSection>
        <PartnerSection
          img={experianPromo}
          name="experian promo"
          header="2. Background Check"
          description="Background Checks are essential for getting a home as a young student. Start today with Experian"
          link=""
        ></PartnerSection>
        <PartnerSection
          img={loanPromo}
          name="loan promo"
          header="3. Zero-Interest Loan"
          description="Housing is expensive, and as former and current students, we know how you feel. That's why we are starting a zero-interest loan program."
          link=""
        ></PartnerSection>
      </section>
    </main>
  )
}

export default HousingSearch
