/**
 * TODO: Needs to be refactored into a Formik component
 */
import React, { useState } from 'react';
import styled from 'styled-components';

import { primaryColor } from '../../../styles';

const StyledBooleanField = styled.div`
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledFormRadio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0;
  }

  input[type='radio']:checked {
    border: 8px solid ${primaryColor};
    margin: 0;
  }

  label {
    margin: 0 0.5rem 0 0;
  }
`;

/**
 * @deprecated and only works on File form system
 */
const BooleanField = ({
  questionType,
  questionName,
  questionAnswer,
  formName,
  trueLabel,
  falseLabel,
  updateFileResponse,
  updateFindResponse
}) => {
  // If the user has selected an answer previously, then we will load
  // that instead
  let loadStoredAnswer = null;

  if (questionAnswer === true) {
    loadStoredAnswer = 'true';
  } else if (questionAnswer === false) {
    loadStoredAnswer = 'false';
  }
  const [value, setValue] = useState(loadStoredAnswer || null);
  const [clickable, setClickable] = useState(true);

  // Adds a debounce to the handleClick function because rapidly
  // switching between the state leads to unexpected UI rendering
  const handleClick = async () => {
    if (!clickable) return;

    setClickable(false);
    setTimeout(() => {
      setClickable(true);
    }, 250);
  };

  // Updates the local state and Redux form state
  const handleBooleanFieldChange = (event) => {
    if (!clickable) return;
    setValue(event.target.value);

    // Switch case used to figure out which redux form state we want to update
    switch (formName) {
      case 'file':
        // Sends a boolean type rather than a string type for true/false state
        updateFileResponse(
          questionType,
          questionName,
          event.target.value === 'true'
        );
        break;
      case 'find':
        updateFindResponse(
          questionType,
          questionName,
          event.target.value === 'true'
        );
        break;
      default:
        break;
    }
  };

  return (
    <StyledBooleanField>
      <StyledFormRadio
        onClick={() => {
          handleClick('true');
        }}
      >
        <label htmlFor="boolean-true">{trueLabel || 'True'}</label>
        <input
          name={`boolean-${questionName}`}
          type="radio"
          id="boolean-true"
          value="true"
          checked={value === 'true'}
          onChange={handleBooleanFieldChange}
        />
      </StyledFormRadio>
      <StyledFormRadio
        onClick={() => {
          handleClick('false');
        }}
      >
        <label htmlFor="boolean-false">{falseLabel || 'False'}</label>
        <input
          name={`boolean-${questionName}`}
          type="radio"
          value="false"
          checked={value === 'false'}
          onChange={handleBooleanFieldChange}
        />
      </StyledFormRadio>
    </StyledBooleanField>
  );
};

export default BooleanField;
