// File types
export const GET_NEXT_FILE_PAGE = 'GET_NEXT_FILE_PAGE';
export const GET_LAST_FILE_PAGE = 'GET_LAST_FILE_PAGE';
export const UPDATE_FILE_RESPONSE = 'UPDATE_REPONSE';
export const GET_FILE_QUESTIONS = 'GET_FILE_QUESTIONS';
export const SUBMIT_FILE_QUESTIONS = 'SUBMIT_FILE_QUESTIONS';
export const FILE_ERROR = 'FILE_ERROR';

// Find types
export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';
export const SET_MATCHED_ONLY = 'SET_MATCHED_ONLY';
export const FIND_ERROR = 'FIND_ERROR';

// Profile Types

export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
