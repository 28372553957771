import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';

import EmailVerified from '../components/layout/EmailVerified';

// Use this for secure routes where the user has to be signed in
// Redirects to homepage if user isn't logged in
const PrivateRoute = ({ children, ...otherProps }) => {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...otherProps}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          <EmailVerified auth={auth}>{children}</EmailVerified>
        ) : (
          <Redirect
            to={{
              pathname: '/signin', // Redirect to homepage
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
