import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import ImageGallery from '../../components/housing/details/ImageGallery'
import DetailMap from '../../components/housing/details/DetailMap'
import styles from '../../styles/housing/housing-details.module.css'

import { ReactComponent as BackChevron } from '../../assets/housing/back-chevron.svg'
import { ReactComponent as ComingSoon } from '../../assets/housing/coming-soon.svg'

const HousingDetail = () => {
  //TODO: Test for Invalid ListingID
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams() //MARIS ListingID
  const [rental, setRental] = useState({})

  const goBack = () => {
    if (
      location.state &&
      location.state.lastPath &&
      location.state.lastPath.includes('/housing')
    ) {
      history.goBack()
    } else {
      history.push('/housing')
    }
  }

  const fetchRental = (id) => {
    fetch(
      `https://api.mlsgrid.com/v2/Property?$filter=OriginatingSystemName%20eq%20%27maris%27%20and%20MlgCanView%20eq%20true%20and%20PropertyType+eq+Enums.PropertyType%27ResidentialLease%27%20and%20StandardStatus+eq+Enums.StandardStatus%27Active%27%20and%20ListingId%20eq%20%27${id}%27&$expand=Media,Rooms,UnitTypes&$count=true`,
      {
        method: 'GET',
        headers: {
          Authorization: process.env.REACT_APP_MLS_GRID_AUTH
        }
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        if (json.value && json.value.length > 0) {
          const r = json.value[0]
          setRental({
            lat: r['Latitude'],
            lng: r['Longitude'],
            price_min: r['ListPrice'],
            beds_max: r['BedroomsTotal'] ? r['BedroomsTotal'] : 0, // beds not consistent
            baths_max: r['BathroomsTotalInteger']
              ? r['BathroomsTotalInteger']
              : 0, // baths not consistent
            photos: r['Media'],
            Garage: r['GarageSpaces'],
            Rooms: r['RoomsTotal'],
            AgentName: r['ListAgentFirstName'] + ' ' + r['ListAgentLastName'],
            ListOfficePhone: r['ListOfficePhone'],
            SquareFeet: r['AboveGradeFinishedArea'] ?? undefined,
            fullAddress: `${r['StreetNumber']} ${r['StreetName']} 
            ${r['StreetSuffix'] ? r['StreetSuffix'] : undefined}, ${
              r['City']
            }, ${r['StateOrProvince']} ${r['PostalCode']}`.replaceAll(
              /undefined,/g,
              ''
            )
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(() => {
    fetchRental(id)
  }, [])

  return (
    <main>
      <div onClick={goBack} id={styles.back_button}>
        <BackChevron></BackChevron>
        Back
      </div>
      <section>
        <ImageGallery rental={rental}></ImageGallery>
      </section>
      <section id={styles.detail_info}>
        <div>
          <h1 id={styles.price}>{rental.price_min}$/mo</h1>
          <h3 id={styles.address}>{rental.fullAddress}</h3>
        </div>
        <div>
          <h4 id={styles.features}>
            {rental.beds_max} beds | {rental.baths_max} baths
            {rental.SquareFeet ? ` | ${rental.SquareFeet} sqft` : ''}
          </h4>
          <h5 id={styles.agent}>Listing Agent: {rental.AgentName}</h5>
        </div>
      </section>
      <section id={styles.map_section}>
        <DetailMap rental={rental}></DetailMap>
      </section>
      <section id={styles.coming_soon}>
        <ComingSoon></ComingSoon>
        <h4>More Housing Info Coming Soon</h4>
      </section>
    </main>
  )
}

export default HousingDetail
