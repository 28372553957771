const genderOptions = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const raceOptions = [
  {
    label: 'American Indian or Alaskan Native',
    value: 'american indian/alaskan native'
  },
  {
    label: 'Asian',
    value: 'asian'
  },
  {
    label: 'Black',
    value: 'black/african american'
  },
  {
    label: 'Hispanic',
    value: 'hispanic/latino'
  },
  {
    label: 'White',
    value: 'white'
  }
];

const gradeOptions = [
  {
    label: 'High school junior',
    value: 'High school junior'
  },
  {
    label: 'High School Senior',
    value: 'High school senior'
  },
  {
    label: 'College Freshman',
    value: 'College freshman'
  },
  {
    label: 'College Sophomore',
    value: 'College sophomore'
  },
  {
    label: 'College Junior',
    value: 'College junior'
  },
  {
    label: 'College Senior',
    value: 'College senior'
  },
  {
    label: 'Masters-level Study',
    value: 'Masters-level study'
  },
  {
    label: 'Doctoral-level Study',
    value: 'Doctoral-level study'
  },
  {
    label: 'Other postgraduate-level study',
    value: 'Other postgraduate-level study'
  },
  {
    label: 'None of the Above',
    value: 'none'
  }
];

const stateOptions = [
  {
    label: 'Alabama',
    value: 'Alabama'
  },
  {
    label: 'Alaska',
    value: 'Alaska'
  },
  {
    label: 'Alberta',
    value: 'Alberta'
  },
  {
    label: 'Austria',
    value: 'Austria'
  },
  {
    label: 'Arizona',
    value: 'Arizona'
  },
  {
    label: 'Arkansas',
    value: 'Arkansas'
  },
  {
    label: 'British Columbia',
    value: 'British Columbia'
  },
  {
    label: 'California',
    value: 'California'
  },
  {
    label: 'Colorado',
    value: 'Colorado'
  },
  {
    label: 'Connecticut',
    value: 'Connecticut'
  },
  {
    label: 'Delaware',
    value: 'Delaware'
  },
  {
    label: 'District of Columbia',
    value: 'District of Columbia'
  },
  {
    label: 'Florida',
    value: 'Florida'
  },
  {
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    label: 'Hawaii',
    value: 'Hawaii'
  },
  {
    label: 'Idaho',
    value: 'Idaho'
  },
  {
    label: 'Illinois',
    value: 'Illinois'
  },
  {
    label: 'Indiana',
    value: 'Indiana'
  },
  {
    label: 'Iowa',
    value: 'Iowa'
  },
  {
    label: 'Kansas',
    value: 'Kansas'
  },
  {
    label: 'Kentucky',
    value: 'Kentucky'
  },
  {
    label: 'Louisiana',
    value: 'Louisiana'
  },
  {
    label: 'Maine',
    value: 'Maine'
  },
  {
    label: 'Manitoba',
    value: 'Manitoba'
  },
  {
    label: 'Maryland',
    value: 'Maryland'
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts'
  },
  {
    label: 'Michigan',
    value: 'Michigan'
  },
  {
    label: 'Minnesota',
    value: 'Minnesota'
  },
  {
    label: 'Mississippi',
    value: 'Mississippi'
  },
  {
    label: 'Missouri',
    value: 'Missouri'
  },
  {
    label: 'Montana',
    value: 'Montana'
  },
  {
    label: 'Nebraska',
    value: 'Nebraska'
  },
  {
    label: 'Nevada',
    value: 'Nevada'
  },
  {
    label: 'New Brunswick',
    value: 'New Brunswick'
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire'
  },
  {
    label: 'New Jersey',
    value: 'New Jersey'
  },
  {
    label: 'New Mexico',
    value: 'New Mexico'
  },
  {
    label: 'New York',
    value: 'New York'
  },
  {
    label: 'Newfoundland',
    value: 'Newfoundland'
  },
  {
    label: 'North Carolina',
    value: 'North Carolina'
  },
  {
    label: 'North Dakota',
    value: 'North Dakota'
  },
  {
    label: 'Northwest Territories',
    value: 'Northwest Territories'
  },
  {
    label: 'Nova Scotia',
    value: 'Nova Scotia'
  },
  {
    label: 'Nunavut',
    value: 'Nunavut'
  },
  {
    label: 'Ohio',
    value: 'Ohio'
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma'
  },
  {
    label: 'Ontario',
    value: 'Ontario'
  },
  {
    label: 'Oregon',
    value: 'Oregon'
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania'
  },
  {
    label: 'Prince Edward Island',
    value: 'Prince Edward Island'
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico'
  },
  {
    label: 'Quebec',
    value: 'Quebec'
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island'
  },
  {
    label: 'Saskatchewan',
    value: 'Saskatchewan'
  },
  {
    label: 'South Carolina',
    value: 'South Carolina'
  },
  {
    label: 'South Dakota',
    value: 'South Dakota'
  },
  {
    label: 'Tennessee',
    value: 'Tennessee'
  },
  {
    label: 'Texas',
    value: 'Texas'
  },
  {
    label: 'Utah',
    value: 'Utah'
  },
  {
    label: 'Vermont',
    value: 'Vermont'
  },
  {
    label: 'Virginia',
    value: 'Virginia'
  },
  {
    label: 'Washington',
    value: 'Washington'
  },
  {
    label: 'West Virginia',
    value: 'West Virginia'
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin'
  },
  {
    label: 'Wyoming',
    value: 'Wyoming'
  },
  {
    label: 'Yukon Territory',
    value: 'Yukon Territory'
  }
];

const countryOptions = [
  {
    label: 'Algeria',
    value: 'Algeria'
  },
  {
    label: 'American Samoa',
    value: 'American Samoa'
  },
  {
    label: 'Australia',
    value: 'Australia'
  },
  {
    label: 'Austria',
    value: 'Austria'
  },
  {
    label: 'Bahrain',
    value: 'Bahrain'
  },
  {
    label: 'Belgium',
    value: 'Belgium'
  },
  {
    label: 'Bermuda',
    value: 'Bermuda'
  },
  {
    label: 'Bulgaria',
    value: 'Bulgaria'
  },
  {
    label: 'Canada',
    value: 'Canada'
  },
  {
    label: 'China',
    value: 'China'
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic'
  },
  {
    label: 'Egypt',
    value: 'Egypt'
  },
  {
    label: 'Estonia',
    value: 'Estonia'
  },
  {
    label: 'Finland',
    value: 'Finland'
  },
  {
    label: 'France',
    value: 'France'
  },
  {
    label: 'Germany',
    value: 'Germany'
  },
  {
    label: 'Greece',
    value: 'Greece'
  },
  {
    label: 'Guam',
    value: 'Guam'
  },
  {
    label: 'Hungary',
    value: 'Hungary'
  },
  {
    label: 'Iceland',
    value: 'Iceland'
  },
  {
    label: 'India',
    value: 'India'
  },
  {
    label: 'Iraq',
    value: 'Iraq'
  },
  {
    label: 'Ireland',
    value: 'Ireland'
  },
  {
    label: 'Israel',
    value: 'Israel'
  },
  {
    label: 'Italy',
    value: 'Italy'
  },
  {
    label: 'Jamaica',
    value: 'Jamaica'
  },
  {
    label: 'Japan',
    value: 'Japan'
  },
  {
    label: 'Jordan',
    value: 'Jordan'
  },
  {
    label: 'Kuwait',
    value: 'Kuwait'
  },
  {
    label: 'Latvia',
    value: 'Latvia'
  },
  {
    label: 'Lebanon',
    value: 'Lebanon'
  },
  {
    label: 'Libya',
    value: 'Libya'
  },
  {
    label: 'Lithuania',
    value: 'Lithuania'
  },
  {
    label: 'Malaysia',
    value: 'Malaysia'
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands'
  },
  {
    label: 'Mexico',
    value: 'Mexico'
  },
  {
    label: 'Morocco',
    value: 'Morocco'
  },
  {
    label: 'Netherlands',
    value: 'Netherlands'
  },
  {
    label: 'New Zealand',
    value: 'New Zealand'
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands'
  },
  {
    label: 'Norway',
    value: 'Norway'
  },
  {
    label: 'Oman',
    value: 'Oman'
  },
  {
    label: 'Poland',
    value: 'Poland'
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico'
  },
  {
    label: 'Qatar',
    value: 'Qatar'
  },
  {
    label: 'Romania',
    value: 'Romania'
  },
  {
    label: 'Russia',
    value: 'Russia'
  },
  {
    label: 'Saudi Arabia',
    value: 'Saudi Arabia'
  },
  {
    label: 'Singapore',
    value: 'Singapore'
  },
  {
    label: 'Slovakia',
    value: 'Slovakia'
  },
  {
    label: 'South Africa',
    value: 'South Africa'
  },
  {
    label: 'Spain',
    value: 'Spain'
  },
  {
    label: 'Sweden',
    value: 'Sweden'
  },
  {
    label: 'Switzerland',
    value: 'Switzerland'
  },
  {
    label: 'Taiwan',
    value: 'Taiwan'
  },
  {
    label: 'The Bahamas',
    value: 'The Bahamas'
  },
  {
    label: 'Tunisia',
    value: 'Tunisia'
  },
  {
    label: 'Turkey',
    value: 'Turkey'
  },
  {
    label: 'Ukraine',
    value: 'Ukraine'
  },
  {
    label: 'United Arab Emirates',
    value: 'United Arab Emirates'
  },
  {
    label: 'United Kingdom',
    value: 'United Kingdom'
  },
  {
    label: 'United States',
    value: 'United States'
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands'
  },
  {
    label: 'United States Virgin Islands',
    value: 'United States Virgin Islands'
  }
];

const careerOptions = [
  {
    label: 'librarianship',
    value: 'librarianship'
  },
  {
    label: 'the technical aspects of broadcasting',
    value: 'the technical aspects of broadcasting'
  },
  {
    label: 'opera',
    value:
      'opera and with at least two years or equivalent of college music and opera-oriented training'
  },
  {
    label: 'manufacturing engineering or manufacturing engineering technology',
    value: 'manufacturing engineering or manufacturing engineering technology'
  },
  {
    label: 'public affairs',
    value: 'public affairs'
  },
  {
    label: 'art history',
    value: 'art history'
  },
  {
    label: 'law or law enforcement and justice administration',
    value: 'law or law enforcement and justice administration'
  },
  {
    label: 'actuarial science',
    value: 'actuarial science'
  },
  {
    label: 'music therapy',
    value: 'music therapy'
  },
  {
    label: 'earth sciences',
    value: 'earth sciences, lapidary arts, or other related fields'
  },
  {
    label: 'lapidary arts',
    value: 'lapidary arts'
  },
  {
    label: 'reference or information science',
    value: 'reference or information science'
  },
  {
    label: 'photojournalism',
    value: 'photojournalism'
  },
  {
    label: 'public school teaching',
    value: 'public school teaching'
  },
  {
    label: 'helicopter pilot',
    value: 'a helicopter pilot'
  },
  {
    label: 'geodetic surveying',
    value: 'geodetic surveying'
  },
  {
    label: 'public education',
    value: 'public education'
  },
  {
    label: 'school superintendency',
    value: 'school superintendency'
  },
  {
    label: 'atmospheric science',
    value: 'atmospheric science'
  },
  {
    label: 'practicing planner',
    value: 'a practicing planner'
  },
  {
    label: 'utility industry',
    value: 'a field related to the utility industry and related fields'
  },
  {
    label: 'graphics',
    value: 'graphics'
  },
  {
    label: 'naval engineering',
    value: 'naval engineering'
  },
  {
    label: 'applied geophysics',
    value:
      'applied geophysics or a closely related field, such as geosciences, physics, geology, or earth and environmental sciences'
  },
  {
    label: 'geosciences',
    value:
      'applied geophysics or a closely related field, such as geosciences, physics, geology, or earth and environmental sciences'
  },
  {
    label: 'physics',
    value:
      'applied geophysics or a closely related field, such as geosciences, physics, geology, or earth and environmental sciences'
  },
  {
    label: 'geology',
    value:
      'applied geophysics or a closely related field, such as geosciences, physics, geology, or earth and environmental sciences'
  },
  {
    label: 'earth and environmental sciences',
    value:
      'applied geophysics or a closely related field, such as geosciences, physics, geology, or earth and environmental sciences'
  },
  {
    label: 'government or public service',
    value: 'government or public service'
  },
  {
    label: 'medical assisting',
    value: 'medical assisting'
  },
  {
    label: 'medical or teaching fields',
    value: 'medical or teaching fields'
  },
  {
    label: 'the plastics industry',
    value: 'the plastics industry'
  },
  {
    label: 'electrical energy',
    value: 'a field related to electrical energy'
  },
  {
    label: 'journalism',
    value: 'journalism'
  },
  {
    label: 'government service',
    value: 'government service'
  },
  {
    label: 'religious communication or journalism',
    value: 'religious communication or journalism'
  },
  {
    label: 'the automotive aftermarket or related field',
    value: 'the automotive aftermarket or related field'
  },
  {
    label: 'manufacturing engineering',
    value: 'manufacturing engineering'
  },
  {
    label: 'manufacturing engineering or technology',
    value: 'manufacturing engineering or technology'
  },
  {
    label: 'mathematics, natural sciences, or engineering',
    value: 'mathematics, natural sciences, or engineering'
  },
  {
    label: 'hydraulics, hydrology or geotechnical disciplines',
    value: 'hydraulics, hydrology or geotechnical disciplines'
  },
  {
    label:
      'grain-based food science and technology or in a related area, including oilseeds, in industry, academia, or government',
    value:
      'grain-based food science and technology or in a related area, including oilseeds, in industry, academia, or government'
  },
  {
    label: 'structural engineering',
    value: 'structural engineering'
  },
  {
    label: 'a well-informed citizen',
    value: 'a well-informed citizen'
  },
  {
    label: 'sales or sales management',
    value: 'sales or sales management'
  },
  {
    label: 'biomedical research',
    value: 'biomedical research'
  },
  {
    label: 'a golf course superintendent',
    value: 'a golf course superintendent'
  },
  {
    label: 'business',
    value: 'business'
  },
  {
    label: 'architecture',
    value: 'architecture'
  },
  {
    label: 'full-time ministry',
    value: 'full-time ministry'
  },
  {
    label: 'general aviation',
    value: 'general aviation'
  },
  {
    label: 'public relations and related communications fields',
    value: 'public relations and related communications fields'
  },
  {
    label: 'teaching',
    value: 'teaching'
  },
  {
    label: 'medicine',
    value: 'medicine'
  },
  {
    label: 'academia',
    value: 'academia'
  },
  {
    label: 'manufacturing engineering or manufacturing technology',
    value: 'manufacturing engineering or manufacturing technology'
  },
  {
    label: 'the field of print journalism',
    value: 'the field of print journalism'
  },
  {
    label: 'turfgrass research and instruction',
    value: 'turfgrass research and instruction'
  },
  {
    label: 'costume',
    value: 'costume'
  },
  {
    label: 'health care',
    value: 'health care'
  },
  {
    label: 'a construction-related engineering discipline',
    value: 'a construction-related engineering discipline'
  },
  {
    label: 'the sheep industry',
    value: 'the sheep industry'
  },
  {
    label: 'college teaching',
    value: 'college teaching'
  },
  {
    label: 'north america',
    value: 'north america'
  },
  {
    label: 'nursing leadership, administration, or management',
    value: 'nursing leadership, administration, or management'
  },
  {
    label: 'the confectionery industry',
    value: 'the confectionery industry'
  },
  {
    label:
      'psychology related to ethnic minority populations and hiv/aids issues',
    value:
      'psychology related to ethnic minority populations and hiv/aids issues'
  },
  {
    label: 'aging research',
    value: 'aging research'
  },
  {
    label: 'crimial justice or law enforcement',
    value: 'crimial justice or law enforcement'
  },
  {
    label: 'the heating',
    value: 'the heating'
  },
  {
    label: 'library automation',
    value: 'library automation'
  },
  {
    label: 'automation and information technology',
    value: 'automation and information technology'
  },
  {
    label: 'aviation maintenance',
    value: 'aviation maintenance'
  },
  {
    label: 'performing arts management or arts education',
    value: 'performing arts management or arts education'
  },
  {
    label: 'science or medicine',
    value: 'science or medicine'
  },
  {
    label: 'the public power industry',
    value: 'the public power industry'
  },
  {
    label: 'the opera',
    value: 'the opera'
  },
  {
    label: 'business or engineering',
    value: 'business or engineering'
  },
  {
    label: 'agriculture',
    value: 'agriculture'
  },
  {
    label: 'a ground water-related field',
    value: 'a ground water-related field'
  },
  {
    label: 'the vertical flight industry',
    value: 'the vertical flight industry'
  },
  {
    label: 'the highway transportation industry',
    value: 'the highway transportation industry'
  },
  {
    label: 'the citrus industry',
    value: 'the citrus industry'
  },
  {
    label: 'print journalism',
    value: 'print journalism'
  },
  {
    label: 'television journalism',
    value: 'television journalism'
  },
  {
    label: 'radio journalism',
    value: 'radio journalism'
  },
  {
    label: 'state/local finance',
    value: 'state/local finance'
  },
  {
    label: 'print journalism or advertising',
    value: 'print journalism or advertising'
  },
  {
    label: 'the nuclear utility industry',
    value: 'the nuclear utility industry'
  },
  {
    label: 'newspaper advertising or journalism',
    value: 'newspaper advertising or journalism'
  },
  {
    label: 'newspaper advertising or print journalism',
    value: 'newspaper advertising or print journalism'
  },
  {
    label: 'the nuclear industry',
    value: 'the nuclear industry'
  },
  {
    label: 'public relations',
    value: 'public relations'
  },
  {
    label: 'cancer nursing',
    value: 'cancer nursing'
  },
  {
    label: 'broadcast engineering',
    value: 'broadcast engineering'
  },
  {
    label: 'christian service',
    value: 'christian service'
  },
  {
    label: 'the library automation field',
    value: 'the library automation field'
  },
  {
    label: 'the medical field',
    value: 'the medical field'
  },
  {
    label: 'the nuclear power industry',
    value: 'the nuclear power industry'
  },
  {
    label: 'clinical geriatrics',
    value: 'clinical geriatrics'
  },
  {
    label: 'geriatrics',
    value: 'geriatrics'
  },
  {
    label: 'a strength and conditioning coach',
    value: 'a strength and conditioning coach'
  },
  {
    label: 'the electrical or electrical engineering industry',
    value: 'the electrical or electrical engineering industry'
  },
  {
    label: 'nursing',
    value: 'nursing'
  },
  {
    label: 'public service',
    value: 'public service'
  },
  {
    label: 'the private club industry',
    value: 'the private club industry'
  },
  {
    label: 'substance abuse treatment and prevention',
    value: 'substance abuse treatment and prevention'
  },
  {
    label: 'library automation and information technology',
    value: 'library automation and information technology'
  },
  {
    label: 'crime prevention',
    value: 'crime prevention'
  },
  {
    label: 'policing',
    value: 'policing'
  },
  {
    label: 'sacred music',
    value: 'sacred music'
  },
  {
    label: 'teaching mathematics',
    value: 'teaching mathematics'
  },
  {
    label: 'arboriculture or urban forestry',
    value: 'arboriculture or urban forestry'
  },
  {
    label: 'local government administration',
    value: 'local government administration'
  },
  {
    label: 'manufacturing',
    value: 'manufacturing'
  },
  {
    label: 'law enforcement',
    value: 'law enforcement'
  },
  {
    label: 'neuroscience related to ethnic minority mental health',
    value: 'neuroscience related to ethnic minority mental health'
  },
  {
    label: 'baptist missions',
    value: 'baptist missions'
  },
  {
    label: 'the aviation field',
    value: 'the aviation field'
  },
  {
    label: 'child development',
    value: 'child development'
  },
  {
    label: 'the aggregate industry',
    value: 'the aggregate industry'
  },
  {
    label:
      'the culinary arts or emergency services, such as law enforcement, fire and rescue, or medical care',
    value:
      'the culinary arts or emergency services, such as law enforcement, fire and rescue, or medical care'
  },
  {
    label: 'the field of journalism',
    value: 'the field of journalism'
  },
  {
    label: 'banking',
    value: 'banking'
  },
  {
    label: 'one more of those fields',
    value: 'one more of those fields'
  },
  {
    label: 'the performing arts',
    value: 'the performing arts'
  },
  {
    label: 'real estate',
    value: 'real estate'
  },
  {
    label: 'public or community service',
    value: 'public or community service'
  },
  {
    label: 'journalism or english',
    value: 'journalism or english'
  },
  {
    label: 'the equine industry',
    value: 'the equine industry'
  },
  {
    label: 'broadcasting',
    value: 'broadcasting'
  },
  {
    label: 'the horticulture industry',
    value: 'the horticulture industry'
  },
  {
    label:
      'accounting and involvement in the business department, a club, or the community',
    value:
      'accounting and involvement in the business department, a club, or the community'
  },
  {
    label: 'the athletic training field',
    value: 'the athletic training field'
  },
  {
    label: 'social work',
    value: 'social work'
  },
  {
    label: 'environmental preservation or landscaper restoration',
    value: 'environmental preservation or landscaper restoration'
  },
  {
    label: 'education',
    value: 'education'
  },
  {
    label: 'teaching and college and extracurricular involvement',
    value: 'teaching and college and extracurricular involvement'
  },
  {
    label: 'the actuarial industry',
    value: 'the actuarial industry'
  },
  {
    label: 'insurance',
    value: 'insurance'
  },
  {
    label: 'a biology teacher',
    value: 'a biology teacher'
  },
  {
    label: 'environmental science or management',
    value: 'environmental science or management'
  },
  {
    label: 'elementary education',
    value: 'elementary education'
  },
  {
    label: 'the chemical industry',
    value: 'the chemical industry'
  },
  {
    label: 'the greater toledo area',
    value: 'the greater toledo area'
  },
  {
    label: 'the medical profession',
    value: 'the medical profession'
  },
  {
    label: 'law',
    value: 'law'
  },
  {
    label: 'aeronautics or aerospace engineering',
    value: 'aeronautics or aerospace engineering'
  },
  {
    label: 'music',
    value: 'music'
  },
  {
    label: 'a visual artist',
    value: 'a visual artist'
  },
  {
    label: 'his or her major',
    value: 'his or her major'
  },
  {
    label: 'art studio',
    value: 'art studio'
  },
  {
    label: 'human services',
    value: 'human services'
  },
  {
    label: 'a related industry',
    value: 'a related industry'
  },
  {
    label:
      'advertising, communication, corporate communication, journalism, speech, visual communications, or public relations',
    value:
      'advertising, communication, corporate communication, journalism, speech, visual communications, or public relations'
  },
  {
    label: 'pharmacy, pharmacology, or related chemical science',
    value: 'pharmacy, pharmacology, or related chemical science'
  },
  {
    label: 'broadcast journalism',
    value: 'broadcast journalism'
  },
  {
    label: 'a health care-related profession',
    value: 'a health care-related profession'
  },
  {
    label: 'the geospatial mapping profession',
    value: 'the geospatial mapping profession'
  },
  {
    label: 'sports journalism',
    value: 'sports journalism'
  },
  {
    label: 'one of the technical fields of study offered at escc',
    value: 'one of the technical fields of study offered at escc'
  },
  {
    label: 'one of the fields of study offered at escc',
    value: 'one of the fields of study offered at escc'
  },
  {
    label: 'construction and industry',
    value: 'construction and industry  '
  },
  {
    label: 'childhood/elementary education',
    value: 'childhood/elementary education'
  },
  {
    label: 'a visual arts field',
    value: 'a visual arts field'
  },
  {
    label: 'the helping professions',
    value: 'the helping professions'
  },
  {
    label:
      'retail real estate, real estate development, or shopping center leasing',
    value:
      'retail real estate, real estate development, or shopping center leasing'
  },
  {
    label: 'retail real estate',
    value: 'retail real estate'
  },
  {
    label: 'the visual arts',
    value: 'the visual arts'
  },
  {
    label: 'business or education',
    value: 'business or education'
  },
  {
    label: 'geriatric health care',
    value: 'geriatric health care'
  },
  {
    label: 'education or social science',
    value: 'education or social science'
  },
  {
    label: 'enology, viticulture, or another wine industry-related career',
    value: 'enology, viticulture, or another wine industry-related career'
  },
  {
    label: 'an agricultural-related industry',
    value: 'an agricultural-related industry'
  },
  {
    label: 'pre-veterinary medicine',
    value: 'pre-veterinary medicine'
  },
  {
    label: 'the thoroughbred industry',
    value: 'the thoroughbred industry'
  },
  {
    label: 'the arts, communication or other endeavor',
    value: 'the arts, communication or other endeavor'
  },
  {
    label: 'the hospitality industry',
    value: 'the hospitality industry'
  },
  {
    label: 'the health field',
    value: 'the health field'
  },
  {
    label: 'business administration or marketing',
    value: 'business administration or marketing'
  },
  {
    label: 'medicine or other healthcare-related fields',
    value: 'medicine or other healthcare-related fields'
  },
  {
    label:
      'a conservation officer, law enforcement officer or a career in the medical field',
    value:
      'a conservation officer, law enforcement officer or a career in the medical field'
  },
  {
    label: 'elementary or secondary education',
    value: 'elementary or secondary education'
  },
  {
    label: 'the allied health professions',
    value: 'the allied health professions'
  },
  {
    label: 'law enforcement or a related field',
    value: 'law enforcement or a related field'
  },
  {
    label: 'medicine or healthcare',
    value: 'medicine or healthcare'
  },
  {
    label: 'an educator or health care professional',
    value: 'an educator or health care professional'
  },
  {
    label: 'police work, corrections or other criminal justice fields',
    value: 'police work, corrections or other criminal justice fields'
  },
  {
    label: 'an educational environment',
    value: 'an educational environment'
  },
  {
    label:
      'agricultural marketing, agricultural management, agriculture technology, animal sciences, farming or natural resources management',
    value:
      'agricultural marketing, agricultural management, agriculture technology, animal sciences, farming or natural resources management'
  },
  {
    label: 'instrumental music',
    value: 'instrumental music'
  },
  {
    label: 'nursing or a related health profession',
    value: 'nursing or a related health profession'
  },
  {
    label: 'mathematics or engineering',
    value: 'mathematics or engineering'
  },
  {
    label:
      'wildlife conservation, environmental resource management or economics',
    value:
      'wildlife conservation, environmental resource management or economics'
  },
  {
    label: 'the performing arts and their prior academic performance',
    value: 'the performing arts and their prior academic performance'
  },
  {
    label: 'the actuarial profession',
    value: 'the actuarial profession'
  },
  {
    label: 'communications',
    value: 'communications'
  },
  {
    label: 'counseling, medicine, nursing, psychology or special education',
    value: 'counseling, medicine, nursing, psychology or special education'
  },
  {
    label: 'the field of technology',
    value: 'the field of technology'
  },
  {
    label:
      'a commercial pilot, transport pilot, airport administration or aeronautical engineering',
    value:
      'a commercial pilot, transport pilot, airport administration or aeronautical engineering'
  },
  {
    label: 'the field of education',
    value: 'the field of education'
  },
  {
    label:
      'business, marketing, management, accounting, or other business-related field',
    value:
      'business, marketing, management, accounting, or other business-related field'
  },
  {
    label: 'secondary math education',
    value: 'secondary math education'
  },
  {
    label: 'an accredited vocational, college or university setting',
    value: 'an accredited vocational, college or university setting'
  },
  {
    label: 'an art-related field',
    value: 'an art-related field'
  },
  {
    label: 'a medical service professional',
    value: 'a medical service professional'
  },
  {
    label: 'a medical field',
    value: 'a medical field'
  },
  {
    label: 'computer training',
    value: 'computer training'
  },
  {
    label: 'golf turf management',
    value: 'golf turf management'
  },
  {
    label: 'ministry',
    value: 'ministry'
  },
  {
    label: 'emergency medical services, fire safety, or law enforcement',
    value: 'emergency medical services, fire safety, or law enforcement'
  },
  {
    label:
      'fields relating to banking, resort management, gaming operations, and management/administration (including accounting, finance, information technology, and/or human resources)',
    value:
      'fields relating to banking, resort management, gaming operations, and management/administration (including accounting, finance, information technology, and/or human resources)'
  },
  {
    label: 'news media',
    value: 'news media'
  },
  {
    label: 'the health care industry',
    value: 'the health care industry'
  },
  {
    label: 'the dental field',
    value: 'the dental field'
  },
  {
    label: 'sales or marketing',
    value: 'sales or marketing'
  },
  {
    label: 'green industry communications',
    value: 'green industry communications'
  },
  {
    label: 'electronic or broadcast journalism',
    value: 'electronic or broadcast journalism'
  },
  {
    label: 'ordained ministry',
    value: 'ordained ministry'
  },
  {
    label: 'computer science or engineering',
    value: 'computer science or engineering'
  },
  {
    label: 'animation',
    value: 'animation'
  },
  {
    label: 'archaeology and/or anthropology',
    value: 'archaeology and/or anthropology'
  },
  {
    label: 'a stem field',
    value: 'a stem field'
  },
  {
    label: 'business, marketing, or personal finance',
    value: 'business, marketing, or personal finance'
  },
  {
    label: 'forestry or environmental resources',
    value: 'forestry or environmental resources'
  },
  {
    label: 'a doctor of veterinary science or medical doctor',
    value: 'a doctor of veterinary science or medical doctor'
  },
  {
    label: 'printing technology',
    value: 'printing technology'
  },
  {
    label: 'footwear manufacturing',
    value: 'footwear manufacturing'
  },
  {
    label: 'rangeland management',
    value: 'rangeland management'
  },
  {
    label: 'film, television, or theater production',
    value: 'film, television, or theater production'
  },
  {
    label: 'the hairdressing industry',
    value: 'the hairdressing industry'
  },
  {
    label:
      'science, technology, or healthcare who demonstrates outstanding leadership, community service, and academic performance',
    value:
      'science, technology, or healthcare who demonstrates outstanding leadership, community service, and academic performance'
  },
  {
    label: 'a physician/scientist and training',
    value: 'a physician/scientist and training'
  },
  {
    label: 'radio or television broadcasting',
    value: 'radio or television broadcasting'
  },
  {
    label: 'the arts',
    value: 'the arts'
  },
  {
    label: 'the kentucky workforce',
    value: 'the kentucky workforce'
  },
  {
    label: 'the transportation field',
    value: 'the transportation field'
  },
  {
    label: 'aviation',
    value: 'aviation'
  },
  {
    label: 'the turkey or poultry industry',
    value: 'the turkey or poultry industry'
  },
  {
    label: 'athletic training',
    value: 'athletic training'
  },
  {
    label: 'foliage marketing or growing',
    value: 'foliage marketing or growing'
  },
  {
    label: 'land surveying',
    value: 'land surveying'
  },
  {
    label: 'construction',
    value: 'construction'
  },
  {
    label: 'the pork industry',
    value: 'the pork industry'
  },
  {
    label:
      'mortgage finance, construction management or another construction-related subject',
    value:
      'mortgage finance, construction management or another construction-related subject'
  },
  {
    label: 'public health',
    value: 'public health'
  },
  {
    label: 'teaching physics',
    value: 'teaching physics'
  },
  {
    label: 'the iron and steel industry',
    value: 'the iron and steel industry'
  },
  {
    label: 'the field of neuropsychology',
    value: 'the field of neuropsychology'
  },
  {
    label: 'the tag',
    value: 'the tag'
  },
  {
    label: 'a k-12 technology and/or engineering education teacher',
    value: 'a k-12 technology and/or engineering education teacher'
  },
  {
    label: 'engineering',
    value: 'engineering'
  },
  {
    label: 'an international field or in studying history abroad',
    value: 'an international field or in studying history abroad'
  },
  {
    label: 'english',
    value: 'english'
  },
  {
    label: 'full-time ministry and major in religion',
    value: 'full-time ministry and major in religion'
  },
  {
    label:
      'ministry, government, education, public health, philanthropy or advocacy',
    value:
      'ministry, government, education, public health, philanthropy or advocacy'
  },
  {
    label:
      'computer science, information technology, management information systems, computer engineering or another related field',
    value:
      'computer science, information technology, management information systems, computer engineering or another related field'
  },
  {
    label:
      'a science-related industry, science teaching or scientific research',
    value: 'a science-related industry, science teaching or scientific research'
  },
  {
    label: 'that field',
    value: 'that field'
  },
  {
    label:
      'the hospitality industry including hotel/restaurant management, culinary/foodservice, architecture or interior design',
    value:
      'the hospitality industry including hotel/restaurant management, culinary/foodservice, architecture or interior design'
  }
];

const religionOptions = [
  {
    label: 'Atheist/Agnostic',
    value: 'atheist/agnostic'
  },
  {
    label: 'Bahai',
    value: 'bahai'
  },
  {
    label: 'Baptist',
    value: 'baptist'
  },
  {
    label: 'Catholic',
    value: 'catholic'
  },
  {
    label: 'Christian',
    value: 'christian'
  },
  {
    label: 'Christian science',
    value: 'christian science'
  },
  {
    label: 'Episcopal',
    value: 'episcopal'
  },
  {
    label: 'Islam',
    value: 'islam'
  },
  {
    label: 'Jewish',
    value: 'jewish'
  },
  {
    label: 'Lutheran',
    value: 'lutheran'
  },
  {
    label: 'Methodist',
    value: 'methodist'
  },
  {
    label: 'Mormon',
    value: 'mormon'
  },
  {
    label: 'Orthodox Christian',
    value: 'orthodox christian'
  },
  {
    label: 'Presbyterian',
    value: 'presbyterian'
  },
  {
    label: 'Protestant',
    value: 'protestant'
  },
  {
    label: 'Quaker',
    value: 'quaker'
  },
  {
    label: 'Unitarian Universalist',
    value: 'unitarian universalist'
  }
];

const majorOptions = [
  {
    label: 'Accounting',
    value: 'accounting'
  },
  {
    label: 'acting',
    value: 'acting'
  },
  {
    label: 'actuarial science',
    value: 'actuarial science'
  },
  {
    label: 'administration',
    value: 'administration'
  },
  {
    label: 'advertising',
    value: 'advertising'
  },
  {
    label: 'aerospace engineering',
    value: 'aerospace engineering'
  },
  {
    label: 'agriculture',
    value: 'agriculture'
  },
  {
    label: 'anthropology',
    value: 'anthropology'
  },
  {
    label: 'archaeology',
    value: 'archaeology'
  },
  {
    label: 'architecture',
    value: 'architecture'
  },
  {
    label: 'area studies',
    value: 'area studies'
  },
  {
    label: 'art history',
    value: 'art history'
  },
  {
    label: 'arts',
    value: 'arts'
  },
  {
    label: 'arts and sciences',
    value: 'arts and sciences'
  },
  {
    label: 'astronomy',
    value: 'astronomy'
  },
  {
    label: 'Germany',
    value: 'Germany'
  },
  {
    label: 'audiology and speech pathology',
    value: 'audiology and speech pathology'
  },
  {
    label: 'aviation',
    value: 'aviation'
  },
  {
    label: 'biology',
    value: 'biology'
  },
  {
    label: 'biomedical engineering',
    value: 'biomedical engineering'
  },
  {
    label: 'botany',
    value: 'botany'
  },
  {
    label: 'business',
    value: 'business'
  },
  {
    label: 'caretaking',
    value: 'caretaking'
  },
  {
    label: 'carpenter',
    value: 'carpenter'
  },
  {
    label: 'chemical engineering',
    value: 'chemical engineering'
  },
  {
    label: 'chemistry',
    value: 'chemistry'
  },
  {
    label: 'civil engineering',
    value: 'civil engineering'
  },
  {
    label: 'classics',
    value: 'classics'
  },
  {
    label: 'communications',
    value: 'communications'
  },
  {
    label: 'computer engineering',
    value: 'computer engineering'
  },
  {
    label: 'computer science',
    value: 'computer science'
  },
  {
    label: 'construction trades',
    value: 'construction trades'
  },
  {
    label: 'criminal justice studies',
    value: 'criminal justice studies'
  },
  {
    label: 'culinary arts',
    value: 'culinary arts'
  },
  {
    label: 'dairy',
    value: 'dairy'
  },
  {
    label: 'dance',
    value: 'dance'
  },
  {
    label: 'dentistry',
    value: 'dentistry'
  },
  {
    label: 'design',
    value: 'design'
  },
  {
    label: 'earth and planetary sciences',
    value: 'earth and planetary sciences'
  },
  {
    label: 'ecology',
    value: 'ecology'
  },
  {
    label: 'economics',
    value: 'economics'
  },
  {
    label: 'education',
    value: 'education'
  },
  {
    label: 'electrical engineering',
    value: 'electrical engineering'
  },
  {
    label: 'electrician',
    value: 'electrician'
  },
  {
    label: 'energy engineering',
    value: 'energy engineering'
  },
  {
    label: 'engineering',
    value: 'engineering'
  },
  {
    label: 'english',
    value: 'english'
  },
  {
    label: 'environmental engineering',
    value: 'environmental engineering'
  },
  {
    label: 'environmental studies',
    value: 'environmental studies'
  },
  {
    label: 'equine studies',
    value: 'equine studies'
  },
  {
    label: 'ethnic and cultural studies',
    value: 'ethnic and cultural studies'
  },
  {
    label: 'family and consumer science',
    value: 'family and consumer science'
  },
  {
    label: 'fashion',
    value: 'fashion'
  },
  {
    label: 'film',
    value: 'film'
  },
  {
    label: 'finance',
    value: 'finance'
  },
  {
    label: 'fine arts',
    value: 'fine arts'
  },
  {
    label: 'firefighter',
    value: 'firefighter'
  },
  {
    label: 'fishing',
    value: 'fishing'
  },
  {
    label: 'food and nutrition studies',
    value: 'food and nutrition studies'
  },
  {
    label: 'foreign languages and literatures',
    value: 'foreign languages and literatures'
  },
  {
    label: 'forestry and related sciences',
    value: 'forestry and related sciences'
  },
  {
    label: 'geography',
    value: 'geography'
  },
  {
    label: 'geological engineering',
    value: 'geological engineering'
  },
  {
    label: 'geology',
    value: 'geology'
  },
  {
    label: 'gerontology',
    value: 'gerontology'
  },
  {
    label: 'graphics/illustration',
    value: 'graphics/illustration'
  },
  {
    label: 'healthcare',
    value: 'healthcare'
  },
  {
    label: 'history',
    value: 'history'
  },
  {
    label: 'horticulture',
    value: 'horticulture'
  },
  {
    label: 'hospitality',
    value: 'hospitality'
  },
  {
    label: 'hotel and restaurant industry',
    value: 'hotel and restaurant industry'
  },
  {
    label: 'humanities',
    value: 'humanities'
  },
  {
    label: 'hvac',
    value: 'hvac'
  },
  {
    label: 'industrial engineering',
    value: 'industrial engineering'
  },
  {
    label: 'information technology',
    value: 'information technology'
  },
  {
    label: 'international area studies',
    value: 'international area studies'
  },
  {
    label: 'international business',
    value: 'international business'
  },
  {
    label: 'journalism',
    value: 'journalism'
  },
  {
    label: 'law enforcement',
    value: 'law enforcement'
  },
  {
    label: 'legal studies',
    value: 'legal studies'
  },
  {
    label: 'leisure and fitness studies',
    value: 'leisure and fitness studies'
  },
  {
    label: 'liberal art',
    value: 'liberal art'
  },
  {
    label: 'library studies',
    value: 'library studies'
  },
  {
    label: 'management',
    value: 'management'
  },
  {
    label: 'marine biology',
    value: 'marine biology'
  },
  {
    label: 'marketing',
    value: 'marketing'
  },
  {
    label: 'material science',
    value: 'material science'
  },
  {
    label: 'mathematics',
    value: 'mathematics'
  },
  {
    label: 'mechanic',
    value: 'mechanic'
  },
  {
    label: 'mechanical engineering',
    value: 'mechanical engineering'
  },
  {
    label: 'medicine',
    value: 'medicine'
  },
  {
    label: 'mining engineering',
    value: 'mining engineering'
  },
  {
    label: 'mortuary science',
    value: 'mortuary science'
  },
  {
    label: 'music',
    value: 'music'
  },
  {
    label: 'nuclear engineering',
    value: 'nuclear engineering'
  },
  {
    label: 'nursing',
    value: 'nursing'
  },
  {
    label: 'nutrition and food science',
    value: 'nutrition and food science'
  },
  {
    label: 'operations',
    value: 'operations'
  },
  {
    label: 'optometry',
    value: 'optometry'
  },
  {
    label: 'performance art',
    value: 'performance art'
  },
  {
    label: 'petroleum engineering',
    value: 'petroleum engineering'
  },
  {
    label: 'pharmacy',
    value: 'pharmacy'
  },
  {
    label: 'philosophy',
    value: 'philosophy'
  },
  {
    label: 'photography',
    value: 'photography'
  },
  {
    label: 'physics',
    value: 'physics'
  },
  {
    label: 'political science',
    value: 'political science'
  },
  {
    label: 'psychology',
    value: 'psychology'
  },
  {
    label: 'public health',
    value: 'public health'
  },
  {
    label: 'public policy',
    value: 'public policy'
  },
  {
    label: 'public relations',
    value: 'public relations'
  },
  {
    label: 'real estate',
    value: 'real estate'
  },
  {
    label: 'religion studies',
    value: 'religion studies'
  },
  {
    label: 'sales',
    value: 'sales'
  },
  {
    label: 'science',
    value: 'science'
  },
  {
    label: 'social sciences',
    value: 'social sciences'
  },
  {
    label: 'social work',
    value: 'social work'
  },
  {
    label: 'sociology',
    value: 'sociology'
  },
  {
    label: 'sports studies',
    value: 'sports studies'
  },
  {
    label: 'statistics',
    value: 'statistics'
  },
  {
    label: 'technician',
    value: 'technician'
  },
  {
    label: 'theatre',
    value: 'theatre'
  },
  {
    label: 'therapy',
    value: 'therapy'
  },
  {
    label: 'tourism',
    value: 'tourism'
  },
  {
    label: 'transportation industry',
    value: 'transportation industry'
  },
  {
    label: 'urban studies',
    value: 'urban studies'
  },
  {
    label: 'veterinary sciences',
    value: 'veterinary sciences'
  },
  {
    label: 'visual arts',
    value: 'visual arts'
  },
  {
    label: 'women/gender/sexuality studies',
    value: 'women/gender/sexuality studies'
  },
  {
    label: 'writing',
    value: 'writing'
  },
  {
    label: 'zoology',
    value: 'zoology'
  }
];

const clubOptions = [
  {
    label: 'State Garden Club',
    value: 'State Garden Club'
  },
  {
    label: 'Appaloosa Horse Club',
    value: 'Appaloosa Horse Club'
  },
  {
    label: 'Mortar Board',
    value: 'Mortar Board'
  },
  {
    label: 'Boy Scouts of America',
    value: 'Boy Scouts of America'
  },
  {
    label: 'National Beta Club',
    value: 'National Beta Club'
  },
  {
    label: 'Girl Scouts of America',
    value: 'Girl Scouts of America'
  },
  {
    label: 'Boys and Girls Club',
    value: 'Boys and Girls Club'
  },
  {
    label: 'Glee Club',
    value: 'Glee Club'
  },
  {
    label: 'National Honor Society',
    value: 'National Honor Society'
  },
  {
    label: 'Alumni Association',
    value: 'Alumni Association'
  },
  {
    label: '4-H',
    value: '4-H'
  },
  {
    label: 'Youth For Understanding',
    value: 'Youth For Understanding'
  },
  {
    label: 'Upward Bound',
    value: 'Upward Bound'
  },
  {
    label: 'Optimist Club',
    value: 'Optimist Club'
  },
  {
    label: 'Young American Bowling Alliance/Youth Division',
    value: 'Young American Bowling Alliance/Youth Division'
  },
  {
    label: 'Boy Scouts of America,Girl Scouts of America',
    value: 'Boy Scouts of America,Girl Scouts of America'
  },
  {
    label: 'Rotary Overseas,Youth For Understanding',
    value: 'Rotary Overseas,Youth For Understanding'
  },
  {
    label: 'Block and Bridle Club',
    value: 'Block and Bridle Club'
  },
  {
    label: 'Accounting Society',
    value: 'Accounting Society'
  },
  {
    label: 'U-M Club of Downriver',
    value: 'U-M Club of Downriver'
  },
  {
    label: 'Health and Physical Education Club',
    value: 'Health and Physical Education Club'
  },
  {
    label: 'Girl Scouts of America,Boy Scouts of America',
    value: 'Girl Scouts of America,Boy Scouts of America'
  },
  {
    label: 'Big Brothers and Sisters of Canada',
    value: 'Big Brothers and Sisters of Canada'
  },
  {
    label: 'Boys State/Girls State',
    value: 'Boys State/Girls State'
  },
  {
    label: 'Elks',
    value: 'Elks'
  },
  {
    label: 'California Mathematics Council - South',
    value: 'California Mathematics Council - South'
  },
  {
    label: 'Virginia Tech Cycling Club',
    value: 'Virginia Tech Cycling Club'
  },
  {
    label: 'Packaging Science Club,Food Science Club',
    value: 'Packaging Science Club,Food Science Club'
  },
  {
    label: 'Economic Club of Grand Rapids (Michigan)',
    value: 'Economic Club of Grand Rapids (Michigan)'
  },
  {
    label: 'Blue Key Honor Society',
    value: 'Blue Key Honor Society'
  },
  {
    label: 'Student Government',
    value: 'Student Government'
  },
  {
    label: 'National Art Honor Society',
    value: 'National Art Honor Society'
  },
  {
    label: 'Rotary',
    value: 'Rotary'
  },
  {
    label: 'Key Club,Circle K Club',
    value: 'Key Club,Circle K Club'
  },
  {
    label: 'Key Club',
    value: 'Key Club'
  },
  {
    label: 'Beehive Honor Society',
    value: 'Beehive Honor Society'
  },
  {
    label: 'Boys State/Girls State,Student Council,Student Government',
    value: 'Boys State/Girls State,Student Council,Student Government'
  },
  {
    label: 'National Aviation Explorer',
    value: 'National Aviation Explorer'
  },
  {
    label: 'Explorers Learning for Life',
    value: 'Explorers Learning for Life'
  },
  {
    label: 'Math Team',
    value: 'Math Team'
  },
  {
    label: 'The Cardinal Times',
    value: 'The Cardinal Times'
  },
  {
    label: 'Boy Scouts of America Cascade Pacific Council',
    value: 'Boy Scouts of America Cascade Pacific Council'
  },
  {
    label: 'Student Ambassador',
    value: 'Student Ambassador'
  },
  {
    label: 'Campus Recreation Program',
    value: 'Campus Recreation Program'
  },
  {
    label: 'Student Council',
    value: 'Student Council'
  },
  {
    label: 'School Club',
    value: 'School Club'
  },
  {
    label: 'Student Council,Student Government',
    value: 'Student Council,Student Government'
  },
  {
    label: 'Boy Scout Troop 181 (IN)',
    value: 'Boy Scout Troop 181 (IN)'
  },
  {
    label: 'Debate Club',
    value: 'Debate Club'
  },
  {
    label: 'Troy Interact Club',
    value: 'Troy Interact Club'
  },
  {
    label: 'ASTRA Club',
    value: 'ASTRA Club'
  },
  {
    label: 'Polish Alliance of Brooklyn, Lodge #1903',
    value: 'Polish Alliance of Brooklyn, Lodge #1903'
  },
  {
    label: 'North Jersey Polish American Club',
    value: 'North Jersey Polish American Club'
  },
  {
    label: 'FIDM Fashion Club',
    value: 'FIDM Fashion Club'
  }
];

const militaryOptions = [
  {
    label: '1st Marine Division',
    value: '1st Marine Division'
  },
  {
    label: '25th Infantry Division Association',
    value: '25th Infantry Division Association'
  },
  {
    label: 'Active Duty Servicemember',
    value: 'Active Duty Servicemember'
  },
  {
    label: 'AFSA',
    value: 'AFSA'
  },
  {
    label: 'Air Force',
    value: 'Air Force'
  },
  {
    label: 'Air Force Reserve',
    value: 'Air Force Reserve'
  },
  {
    label: 'Air Force ROTC',
    value: 'Air Force ROTC'
  },
  {
    label: 'Air National Guard',
    value: 'Air National Guard'
  },
  {
    label: 'American Legion',
    value: 'American Legion'
  },
  {
    label: 'American Legion Auxiliary',
    value: 'American Legion Auxiliary'
  },
  {
    label: 'American Legion Veteran',
    value: 'American Legion Veteran'
  },
  {
    label: 'AMVETS',
    value: 'AMVETS'
  },
  {
    label: 'Arizona',
    value: 'Arizona'
  },
  {
    label: 'Arkansas',
    value: 'Arkansas'
  },
  {
    label: 'Armed Forces',
    value: 'Armed Forces'
  },
  {
    label: 'Army',
    value: 'Army'
  },
  {
    label: 'Army National Guard',
    value: 'Army National Guard'
  },
  {
    label: 'Army Reserve',
    value: 'Army Reserve'
  },
  {
    label: 'Army ROTC',
    value: 'Army ROTC'
  },
  {
    label: 'Association of American Foreign Service Women (AAFSW)',
    value: 'Association of American Foreign Service Women (AAFSW)'
  },
  {
    label: 'Cadet',
    value: 'Cadet'
  },
  {
    label: 'Child of 25th Infantry Division Member',
    value: 'Child of 25th Infantry Division Member'
  },
  {
    label: 'Child of Air Force Servicemember',
    value: 'Child of Air Force Servicemember'
  },
  {
    label: 'Child of AMVETS Member',
    value: 'Child of AMVETS Member'
  },
  {
    label: 'Child of Blind Veteran',
    value: 'Child of Blind Veteran'
  },
  {
    label: 'Child of Coast Guard Member',
    value: 'Child of Coast Guard Member'
  },
  {
    label: 'Child of Deceased 1st Marine Division Veteran',
    value: 'Child of Deceased 1st Marine Division Veteran'
  },
  {
    label: 'Child of Deceased 25th Infantry Division Member',
    value: 'Child of Deceased 25th Infantry Division Member'
  },
  {
    label: 'Child of Deceased Military Veteran',
    value: 'Child of Deceased Military Veteran'
  },
  {
    label: 'Child of Deceased National Guard Member',
    value: 'Child of Deceased National Guard Member'
  },
  {
    label: 'Child of Deceased Servicemember',
    value: 'Child of Deceased Servicemember'
  },
  {
    label: 'Child of Disabled 1st Marine Division Veteran',
    value: 'Child of Disabled 1st Marine Division Veteran'
  },
  {
    label: 'Child of Disabled National Guard Servicemember',
    value: 'Child of Disabled National Guard Servicemember'
  },
  {
    label: 'Child of Disabled Veteran',
    value: 'Child of Disabled Veteran'
  },
  {
    label: 'Child of Honorably Discharged Veteran',
    value: 'Child of Honorably Discharged Veteran'
  },
  {
    label: 'Child of Marine Corps Member',
    value: 'Child of Marine Corps Member'
  },
  {
    label: 'Child of MIA Servicemember',
    value: 'Child of MIA Servicemember'
  },
  {
    label: 'Child of Military Personnel',
    value: 'Child of Military Personnel'
  },
  {
    label: 'Child of Military Veteran',
    value: 'Child of Military Veteran'
  },
  {
    label: 'Child of National Guard Member',
    value: 'Child of National Guard Member'
  },
  {
    label: 'Child of Naval Servicemember',
    value: 'Child of Naval Servicemember'
  },
  {
    label: 'Child of POW',
    value: 'Child of POW'
  },
  {
    label: 'Child of Retired Servicemember',
    value: 'Child of Retired Servicemember'
  },
  {
    label: 'Child of Second Marine Division Servicemember',
    value: 'Child of Second Marine Division Servicemember'
  },
  {
    label: 'Student Council',
    value: 'Student Council'
  },
  {
    label: 'School Club',
    value: 'School Club'
  },
  {
    label: 'Student Council,Student Government',
    value: 'Student Council,Student Government'
  },
  {
    label: 'Boy Scout Troop 181 (IN)',
    value: 'Boy Scout Troop 181 (IN)'
  },
  {
    label: 'Debate Club',
    value: 'Debate Club'
  },
  {
    label: 'Troy Interact Club',
    value: 'Troy Interact Club'
  },
  {
    label: 'ASTRA Club',
    value: 'ASTRA Club'
  },
  {
    label: 'Polish Alliance of Brooklyn, Lodge #1903',
    value: 'Polish Alliance of Brooklyn, Lodge #1903'
  },
  {
    label: 'North Jersey Polish American Club',
    value: 'North Jersey Polish American Club'
  },
  {
    label: 'FIDM Fashion Club',
    value: 'FIDM Fashion Club'
  }
];

const corporationOptions = [
  {
    label: 'Columbia Vista Corporation Scholarship',
    value: 'Columbia Vista Corporation Scholarship'
  },
  {
    label: 'Mylan',
    value: 'Mylan'
  },
  {
    label: 'Davidson Companies',
    value: 'Davidson Companies'
  },
  {
    label: 'Denver County Club',
    value: 'Denver County Club'
  },
  {
    label: 'Schwebel Baking Company',
    value: 'Schwebel Baking Company'
  },
  {
    label: 'Halton Company',
    value: 'Halton Company'
  },
  {
    label: 'Federal Government',
    value: 'Federal Government'
  },
  {
    label: 'Coca-Cola Bottling Company',
    value: 'Coca-Cola Bottling Company'
  },
  {
    label: 'Eastman Kodak',
    value: 'Eastman Kodak'
  },
  {
    label: 'McDonalds',
    value: 'McDonalds'
  },
  {
    label: 'Shelbyville Bottling Company, Inc.',
    value: 'Shelbyville Bottling Company'
  },
  {
    label: 'Juliette Fowler Homes, Inc.',
    value: 'Juliette Fowler Homes'
  },
  {
    label: 'Baptist Hospital (FL)',
    value: 'Baptist Hospital (FL)'
  },
  {
    label: 'Beaman Bottling Company, Inc.',
    value: 'Beaman Bottling Company'
  },
  {
    label: 'A.T. Cross Company',
    value: 'A.T. Cross Company'
  },
  {
    label: 'ABC Stores',
    value: 'ABC Stores'
  },
  {
    label: 'Affinia Products Corporation',
    value: 'Affinia Products Corporation'
  },
  {
    label: 'Airways Freight',
    value: 'Airways Freight'
  },
  {
    label: 'Alcoa Corporation',
    value: 'Alcoa Corporation'
  },
  {
    label: 'Alexander & Baldwin',
    value: 'Alexander & Baldwin'
  },
  {
    label: 'Association of Energy Service Companies',
    value: 'Association of Energy Service Companies'
  },
  {
    label: 'Auto Services Co',
    value: 'Auto Services Co'
  },
  {
    label: 'Bartoo Associates',
    value: 'Bartoo Associates'
  },
  {
    label: 'Bend Broadband',
    value: 'Bend Broadband'
  },
  {
    label: 'Bend Cable Communications',
    value: 'Bend Cable Communications'
  },
  {
    label: 'Bend Garbage & Recycling',
    value: 'Bend Garbage & Recycling'
  },
  {
    label: 'Bristol Bay Native Corporation (BBNC)',
    value: 'Bristol Bay Native Corporation (BBNC)'
  },
  {
    label: 'Lewis Bear Company',
    value: 'Lewis Bear Company'
  },
  {
    label: 'Dow Chemical Company',
    value: 'Dow Chemical Company'
  },
  {
    label: 'Kohler',
    value: 'Kohler'
  },
  {
    label: 'Tiepet, Inc.,Universal Fibers',
    value: 'Tiepet, Inc.,Universal Fibers'
  },
  {
    label: 'Central Power & Light',
    value: 'Central Power & Light'
  },
  {
    label: 'Alcoa Corporation,Child of Alcoa Employee',
    value: 'Alcoa Corporation,Child of Alcoa Employee'
  },
  {
    label: 'Federal Mogul Corporation',
    value: 'Federal Mogul Corporation'
  },
  {
    label: 'Volkswagen/Audi Dealer',
    value: 'Volkswagen/Audi Dealer'
  },
  {
    label: 'Mazda Motor',
    value: 'Mazda Motor'
  },
  {
    label: 'APEX',
    value: 'APEX'
  },
  {
    label: 'Coastal Corporation',
    value: 'Coastal Corporation'
  },
  {
    label: 'Owen Electric Cooperative',
    value: 'Owen Electric Cooperative'
  },
  {
    label: 'Calavo Growers of California',
    value: 'Calavo Growers of California'
  },
  {
    label: 'Brunswick Corporation',
    value: 'Brunswick Corporation'
  },
  {
    label: 'Duke Energy',
    value: 'Duke Energy'
  },
  {
    label: 'Hutchens Industries',
    value: 'Hutchens Industries'
  },
  {
    label: 'Hagale Industries',
    value: 'Hagale Industries'
  },
  {
    label: 'Gannett Co., Inc.',
    value: 'Gannett Co., Inc.'
  },
  {
    label: 'Sodexho-Marriott',
    value: 'Sodexho-Marriott'
  },
  {
    label: 'Libbey, Inc.',
    value: 'Libbey, Inc.'
  },
  {
    label: 'Association of Energy Service Companies,Energy Service Companies',
    value: 'Association of Energy Service Companies,Energy Service Companies'
  },
  {
    label: 'NASA',
    value: 'NASA'
  },
  {
    label: 'Tandy Corporation',
    value: 'Tandy Corporation'
  },
  {
    label: 'Noble Communications',
    value: 'Noble Communications'
  },
  {
    label: 'HNTB Corporation',
    value: 'HNTB Corporation'
  },
  {
    label: 'Congressional Black Caucus Foundation, Inc.',
    value: 'Congressional Black Caucus Foundation, Inc.'
  },
  {
    label: 'Wilkoff Color Corp.',
    value: 'Wilkoff Color Corp.'
  },
  {
    label: 'Hanes Companies, Inc.',
    value: 'Hanes Companies, Inc.'
  },
  {
    label: 'National Welders Supply Company, Inc.',
    value: 'National Welders Supply Company, Inc.'
  },
  {
    label: 'Goodyear Tire and Rubber Company',
    value: 'Goodyear Tire and Rubber Company'
  },
  {
    label: 'Child of Piggly Wiggly Employee',
    value: 'Child of Piggly Wiggly Employee'
  },
  {
    label: 'Walmart',
    value: 'Walmart'
  },
  {
    label: 'Caterpillar',
    value: 'Caterpillar'
  },
  {
    label: 'Weight Watchers',
    value: 'Weight Watchers'
  },
  {
    label: 'Simpson Paper Company',
    value: 'Simpson Paper Company'
  },
  {
    label: 'Ford',
    value: 'Ford'
  },
  {
    label: 'Cooperative Extension',
    value: 'Cooperative Extension'
  },
  {
    label: 'Pilgrims Pride Industries',
    value: 'Pilgrims Pride Industries'
  },
  {
    label: 'Child of Finch Ranches Employee',
    value: 'Child of Finch Ranches Employee'
  },
  {
    label: 'Kinkos',
    value: 'Kinkos'
  },
  {
    label: 'Daltech',
    value: 'Daltech'
  },
  {
    label: 'Country Ribbon',
    value: 'Country Ribbon'
  },
  {
    label: 'CS STARS LLC',
    value: 'CS STARS LLC'
  },
  {
    label: 'Raytheon',
    value: 'Raytheon'
  },
  {
    label: 'American Hotel & Lodging Association (AH&LA)',
    value: 'American Hotel & Lodging Association (AH&LA)'
  },
  {
    label: 'Queens Medical Center (HI)',
    value: 'Queens Medical Center (HI)'
  },
  {
    label: 'Safeway Hawaii',
    value: 'Safeway Hawaii'
  },
  {
    label: 'Outrigger Enterprises (HI)',
    value: 'Outrigger Enterprises (HI)'
  },
  {
    label:
      'Title Guaranty Escrow Services, Inc.,Title Guaranty of Hawaii, Inc.',
    value: 'Title Guaranty Escrow Services, Inc.,Title Guaranty of Hawaii, Inc.'
  },
  {
    label: 'Kahala Senior Living Community, Inc.',
    value: 'Kahala Senior Living Community, Inc.'
  },
  {
    label: 'Tesoro Hawaii',
    value: 'Tesoro Hawaii'
  },
  {
    label: 'Clinical Laboratories of Hawaii, LLP,Pan Pacific Pathologies, LLC',
    value: 'Clinical Laboratories of Hawaii, LLP,Pan Pacific Pathologies, LLC'
  },
  {
    label: 'ABC Stores,Company Island Gourmet Markets',
    value: 'ABC Stores,Company Island Gourmet Markets'
  },
  {
    label: 'Alexander & Baldwin, Inc.',
    value: 'Alexander & Baldwin, Inc.'
  },
  {
    label: 'Taylor Made Label Company',
    value: 'Taylor Made Label Company'
  },
  {
    label: 'Stimson Lumber Company',
    value: 'Stimson Lumber Company'
  },
  {
    label: 'Salem Electric',
    value: 'Salem Electric'
  },
  {
    label: 'Central Maine Power',
    value: 'Central Maine Power'
  },
  {
    label: 'Calbag Metals',
    value: 'Calbag Metals'
  },
  {
    label: 'Pacific Northwest Federal Credit Union',
    value: 'Pacific Northwest Federal Credit Union'
  },
  {
    label: 'PacificSource',
    value: 'PacificSource'
  },
  {
    label: 'Oregon Trawl Commission',
    value: 'Oregon Trawl Commission'
  },
  {
    label: 'Oregon Student Access Commission',
    value: 'Oregon Student Access Commission'
  },
  {
    label: 'Bonita Pioneer Packaging Company',
    value: 'Bonita Pioneer Packaging Company'
  },
  {
    label: 'Swanson Brothers Lumber Company',
    value: 'Swanson Brothers Lumber Company'
  },
  {
    label: 'Willamette View Terrace,Willamette View Health Care Center',
    value: 'Willamette View Terrace,Willamette View Health Care Center'
  },
  {
    label: 'Papas Pizza',
    value: 'Papas Pizza'
  },
  {
    label: 'Walsh Construction Company',
    value: 'Walsh Construction Company'
  },
  {
    label: 'Roseburg Forest Products Company',
    value: 'Roseburg Forest Products Company'
  },
  {
    label: 'Essex General Construction',
    value: 'Essex General Construction'
  },
  {
    label: 'U.S. Bank',
    value: 'U.S. Bank'
  },
  {
    label:
      'Oregon Department of Transportation,Oregon Department of Parks and Recreation',
    value:
      'Oregon Department of Transportation,Oregon Department of Parks and Recreation'
  },
  {
    label:
      'Hadlock Plastics,Martin Fletcher,Highlander Partners,Eagle Materials,Performance Chemicals and Ingredients',
    value:
      'Hadlock Plastics,Martin Fletcher,Highlander Partners,Eagle Materials,Performance Chemicals and Ingredients'
  },
  {
    label: 'Insitu, Inc.',
    value: 'Insitu, Inc.'
  },
  {
    label: 'Waste Control Systems, Inc.',
    value: 'Waste Control Systems, Inc.'
  },
  {
    label: 'KBPS Radio',
    value: 'KBPS Radio'
  },
  {
    label: 'Bartoo Associates, LLC',
    value: 'Bartoo Associates, LLC'
  },
  {
    label:
      'High Country Disposal,Bend Garbage & Recycling,Deschutes Recycling,Mid Oregon Recycling,Deschutes Transfer',
    value:
      'High Country Disposal,Bend Garbage & Recycling,Deschutes Recycling,Mid Oregon Recycling,Deschutes Transfer'
  },
  {
    label: 'Albina Fuel Company',
    value: 'Albina Fuel Company'
  },
  {
    label: 'Columbus Regional Hospital',
    value: 'Columbus Regional Hospital'
  },
  {
    label: 'Four Seasons Retirement Center',
    value: 'Four Seasons Retirement Center'
  },
  {
    label: 'McKown Studio and Galleries',
    value: 'McKown Studio and Galleries'
  },
  {
    label: 'Company Controlled by Mason and Don Foertsch',
    value: 'Company Controlled by Mason and Don Foertsch'
  },
  {
    label: 'Spencer Industries Incorporated',
    value: 'Spencer Industries Incorporated'
  },
  {
    label: 'KidsPlay, Inc.',
    value: 'KidsPlay, Inc.'
  },
  {
    label: 'SABIC Innovative Plastics',
    value: 'SABIC Innovative Plastics'
  },
  {
    label: 'Branchville Correctional Facility',
    value: 'Branchville Correctional Facility'
  },
  {
    label: 'Gemtron Corporation,Schott North America, Inc.',
    value: 'Gemtron Corporation,Schott North America, Inc.'
  },
  {
    label: 'Vincennes Township Fire Department',
    value: 'Vincennes Township Fire Department'
  },
  {
    label: 'Umatilla Electric Cooperative',
    value: 'Umatilla Electric Cooperative'
  },
  {
    label:
      'Bend Broadband,Bend Cable Communications,Tykeson/Associates Enterprises,ZoloMedia,Central Oregon Cable Advertising',
    value:
      'Bend Broadband,Bend Cable Communications,Tykeson/Associates Enterprises,ZoloMedia,Central Oregon Cable Advertising'
  },
  {
    label: 'Columbia Cascade Company',
    value: 'Columbia Cascade Company'
  },
  {
    label: 'Ulland Brothers, Inc.',
    value: 'Ulland Brothers, Inc.'
  },
  {
    label: 'Paine Electronics',
    value: 'Paine Electronics'
  },
  {
    label: 'Carter-Lee Lumber Company',
    value: 'Carter-Lee Lumber Company'
  },
  {
    label: 'Touch of Class',
    value: 'Touch of Class'
  },
  {
    label: 'Federal-Mogul Dumas',
    value: 'Federal-Mogul Dumas'
  },
  {
    label: 'L.A. Darling',
    value: 'L.A. Darling'
  },
  {
    label: 'Auto Services Co, Inc.',
    value: 'Auto Services Co, Inc.'
  },
  {
    label: 'ARMCA (AR)',
    value: 'ARMCA (AR)'
  },
  {
    label: 'Airways Freight, Inc.',
    value: 'Airways Freight, Inc.'
  },
  {
    label: 'Meshberger / LICA',
    value: 'Meshberger / LICA'
  },
  {
    label: 'Kriz-Davis, Co. (NE)',
    value: 'Kriz-Davis, Co. (NE)'
  },
  {
    label: 'Eakes Office Plus (NE)',
    value: 'Eakes Office Plus (NE)'
  },
  {
    label: 'Consolidated Systems, Inc. (SC)',
    value: 'Consolidated Systems, Inc. (SC)'
  },
  {
    label: 'Carolina Coca-Cola Bottling Company',
    value: 'Carolina Coca-Cola Bottling Company'
  },
  {
    label: 'First Choice (SC)',
    value: 'First Choice (SC)'
  },
  {
    label: 'Phelps Memorial Health Center (PMHC)',
    value: 'Phelps Memorial Health Center (PMHC)'
  },
  {
    label: 'F.P. Horak Company',
    value: 'F.P. Horak Company'
  },
  {
    label: 'GM Powertrain Bay City',
    value: 'GM Powertrain Bay City'
  },
  {
    label: 'Citizens Bank',
    value: 'Citizens Bank'
  },
  {
    label: 'Meijer, Inc.',
    value: 'Meijer, Inc.'
  },
  {
    label: 'Endo Pharmaceuticals',
    value: 'Endo Pharmaceuticals'
  },
  {
    label: 'Corporation Service Company (CSC)',
    value: 'Corporation Service Company (CSC)'
  },
  {
    label: 'Orchards Golf Course (MA)',
    value: 'Orchards Golf Course (MA)'
  },
  {
    label: 'Springfield Newspapers 25-Year Club',
    value: 'Springfield Newspapers 25-Year Club'
  },
  {
    label: 'Deerfield Plastics Company, Inc. (MA)',
    value: 'Deerfield Plastics Company, Inc. (MA)'
  },
  {
    label: 'Little Caesars Pizza (AR)',
    value: 'Little Caesars Pizza (AR)'
  },
  {
    label: 'Scroll Technologies (AR)',
    value: 'Scroll Technologies (AR)'
  },
  {
    label: 'Nabholz Construction Corporation,Nabholz, Inc.',
    value: 'Nabholz Construction Corporation,Nabholz, Inc.'
  },
  {
    label: 'Texas Electric Cooperatives, Inc.',
    value: 'Texas Electric Cooperatives, Inc.'
  },
  {
    label: 'Big Sandy Furniture',
    value: 'Big Sandy Furniture'
  },
  {
    label: 'Centerpoint Medical Center',
    value: 'Centerpoint Medical Center'
  },
  {
    label: 'Qual Creek Golf and County Club (OK)',
    value: 'Qual Creek Golf and County Club (OK)'
  },
  {
    label: 'Smith Dairy',
    value: 'Smith Dairy'
  }
];

const sportsOptions = [
  {
    label: 'Archery',
    value: 'archery'
  },
  {
    label: 'Athlete',
    value: 'athlete'
  },
  {
    label: 'Baseball',
    value: 'baseball'
  },
  {
    label: 'Basketball',
    value: 'basketball'
  },
  {
    label: 'Bicycling',
    value: 'bicycling'
  },
  {
    label: 'Bowling',
    value: 'bowling'
  },
  {
    label: 'Canoeing',
    value: 'canoeing'
  },
  {
    label: 'Cheerleading',
    value: 'cheerleading'
  },
  {
    label: 'Club/extracurricular sports',
    value: 'club/extracurricular sports'
  },
  {
    label: 'Combat sports',
    value: 'combat sports'
  },
  {
    label: 'Crew',
    value: 'crew'
  },
  {
    label: 'Cross-country',
    value: 'cross-country'
  },
  {
    label: 'Diving',
    value: 'diving'
  },
  {
    label: 'Equestrian',
    value: 'equestrian'
  },
  {
    label: 'fencing',
    value: 'fencing'
  },
  {
    label: 'field hockey',
    value: 'field hockey'
  },
  {
    label: 'fishing',
    value: 'fishing'
  },
  {
    label: 'football',
    value: 'football'
  },
  {
    label: 'golf',
    value: 'golf'
  },
  {
    label: 'gymnastics',
    value: 'gymnastics'
  },
  {
    label: 'hunting',
    value: 'hunting'
  },
  {
    label: 'ice hockey',
    value: 'ice hockey'
  },
  {
    label: 'ice skating',
    value: 'ice skating'
  },
  {
    label: 'kayaking',
    value: 'kayaking'
  },
  {
    label: 'lacrosse',
    value: 'lacrosse'
  },
  {
    label: 'racquetball',
    value: 'racquetball'
  },
  {
    label: 'riflery/shooting',
    value: 'riflery/shooting'
  },
  {
    label: 'rodeo',
    value: 'rodeo'
  },
  {
    label: 'roller skating',
    value: 'roller skating'
  },
  {
    label: 'rugby',
    value: 'rugby'
  },
  {
    label: 'skiing',
    value: 'skiing'
  },
  {
    label: 'snowboarding',
    value: 'snowboarding'
  },
  {
    label: 'soccer',
    value: 'soccer'
  },
  {
    label: 'softball',
    value: 'softball'
  },
  {
    label: 'strength and conditioning',
    value: 'strength and conditioning'
  },
  {
    label: 'surfing',
    value: 'surfing'
  },
  {
    label: 'swimming',
    value: 'swimming'
  },
  {
    label: 'tennis',
    value: 'tennis'
  },
  {
    label: 'track and field',
    value: 'track and field'
  },
  {
    label: 'varsity sports',
    value: 'varsity sports'
  },
  {
    label: 'volleyball',
    value: 'volleyball'
  },
  {
    label: 'water polo',
    value: 'water polo'
  },
  {
    label: 'water skiing',
    value: 'water skiing'
  }
];

const organizationOptions = [
  {
    label: 'Association of American Geographers',
    value: 'Association of American Geographers'
  },
  {
    label: 'Association of the Sons of Poland',
    value: 'Association of the Sons of Poland'
  },
  {
    label: 'ATU',
    value: 'ATU'
  },
  {
    label: 'Automotive Recyclers Association',
    value: 'Automotive Recyclers Association'
  },
  {
    label: 'Auxiliary Unit',
    value: 'Auxiliary Unit'
  },
  {
    label: 'AWANA International',
    value: 'AWANA International'
  },
  {
    label: 'BAC',
    value: 'BAC'
  },
  {
    label: 'BEA',
    value: 'BEA'
  },
  {
    label: 'Brock University',
    value: 'Brock University'
  },
  {
    label: 'California Teachers Association (CTA)',
    value: 'California Teachers Association (CTA)'
  },
  {
    label: 'Catholic Youth Organization',
    value: 'Catholic Youth Organization'
  },
  {
    label: 'CCNE',
    value: 'CCNE'
  },
  {
    label: 'Child of Automotive Recyclers Association Member',
    value: 'Child of Automotive Recyclers Association Member'
  },
  {
    label: 'Child of Second Indianhead Division Association Member',
    value: 'Child of Second Indianhead Division Association Member'
  },
  {
    label:
      'Christian Youth Fellowship of the Christian Church (Disciples of Christ)',
    value:
      'Christian Youth Fellowship of the Christian Church (Disciples of Christ)'
  },
  {
    label: 'Coast Guard',
    value: 'Coast Guard'
  },
  {
    label: 'Coast Guard Reserve',
    value: 'Coast Guard Reserve'
  },
  {
    label: 'Columbia Vista Corporation',
    value: 'Columbia Vista Corporation'
  },
  {
    label: 'Columbian Squires',
    value: 'Columbian Squires'
  },
  {
    label: 'Congregational',
    value: 'Congregational'
  },
  {
    label: 'Costume Society of America',
    value: 'Costume Society of America'
  },
  {
    label: 'Council of Energy Resource Tribes',
    value: 'Council of Energy Resource Tribes'
  },
  {
    label: 'CSA Fraternal Life',
    value: 'CSA Fraternal Life'
  },
  {
    label: 'CWA',
    value: 'CWA'
  },
  {
    label: 'Danish Sisterhood of America',
    value: 'Danish Sisterhood of America'
  },
  {
    label: 'Daughters of Penelope',
    value: 'Daughters of Penelope'
  },
  {
    label: 'Delta Delta Delta',
    value: 'Delta Delta Delta'
  },
  {
    label: 'Delta Gamma',
    value: 'Delta Gamma'
  },
  {
    label: 'Disciples of Christ/United Church of Christ',
    value: 'Disciples of Christ/United Church of Christ'
  },
  {
    label: 'Eagle Scout',
    value: 'Eagle Scout'
  },
  {
    label: 'Fleet Marine Corps Reserve',
    value: 'Fleet Marine Corps Reserve'
  },
  {
    label: 'Fleet Reserve',
    value: 'Fleet Reserve'
  },
  {
    label: 'Fleet Reserve Association',
    value: 'Fleet Reserve Association'
  },
  {
    label: 'Fordham University',
    value: 'Fordham University'
  },
  {
    label: 'FRA',
    value: 'FRA'
  },
  {
    label: 'Future Farmers of America (FFA)',
    value: 'Future Farmers of America (FFA)'
  },
  {
    label: 'General Federation of Womens Clubs of Massachusetts',
    value: 'General Federation of Womens Clubs of Massachusetts'
  },
  {
    label: 'Gold Award',
    value: 'Gold Award'
  },
  {
    label: 'Graff Automobile Association',
    value: 'Graff Automobile Association'
  },
  {
    label: 'Houghton',
    value: 'Houghton'
  },
  {
    label: 'Houghton College',
    value: 'Houghton College'
  },
  {
    label: 'Indianapolis Automobile Trade Association',
    value: 'Indianapolis Automobile Trade Association'
  },
  {
    label: 'International Brotherhood of Electrical Workers (IBEW)',
    value: 'International Brotherhood of Electrical Workers (IBEW)'
  },
  {
    label: 'International Buckskin Horse Association',
    value: 'International Buckskin Horse Association'
  },
  {
    label: 'International Executive Housekeepers Association',
    value: 'International Executive Housekeepers Association'
  },
  {
    label: 'Issaquah Branch Loan Officer',
    value: 'Issaquah Branch Loan Officer'
  },
  {
    label: 'Italian Catholic Federation (ICF)',
    value: 'Italian Catholic Federation (ICF)'
  },
  {
    label: 'IUE',
    value: 'IUE'
  },
  {
    label: 'Japanese American Citizens League',
    value: 'Japanese American Citizens League'
  },
  {
    label: 'Jewish War Veterans of the USA',
    value: 'Jewish War Veterans of the USA'
  },
  {
    label:
      'Junior Girls Unit of the Ladies Auxiliary to the Veterans of Foreign Wars',
    value:
      'Junior Girls Unit of the Ladies Auxiliary to the Veterans of Foreign Wars'
  },
  {
    label: 'Kappa Epsilon',
    value: 'Kappa Epsilon'
  },
  {
    label: 'Kappa Kappa Gamma',
    value: 'Kappa Kappa Gamma'
  },
  {
    label: 'KGI',
    value: 'KGI'
  },
  {
    label: 'Knights of Columbus',
    value: 'Knights of Columbus'
  },
  {
    label: 'LDS',
    value: 'LDS'
  },
  {
    label: 'Loyola Alumni Association',
    value: 'Loyola Alumni Association'
  },
  {
    label: 'Lutheran',
    value: 'Lutheran'
  },
  {
    label: 'Marine Corps',
    value: 'Marine Corps'
  },
  {
    label: 'Marine Division',
    value: 'Marine Division'
  },
  {
    label: 'Masons',
    value: 'Masons'
  },
  {
    label: 'Modern Woodmen of America',
    value: 'Modern Woodmen of America'
  },
  {
    label: 'Mortarboard',
    value: 'Mortarboard'
  },
  {
    label: 'NAPFE',
    value: 'NAPFE'
  },
  {
    label: 'National Academy of American Scholars (NAAS)',
    value: 'National Academy of American Scholars (NAAS)'
  },
  {
    label: 'National Association for the Advancement of Colored People (NAACP)',
    value: 'National Association for the Advancement of Colored People (NAACP)'
  },
  {
    label: 'National Association of Congregational',
    value: 'National Association of Congregational'
  },
  {
    label: 'National Athletic Trainers Association',
    value: 'National Athletic Trainers Association'
  },
  {
    label: 'National Beta Club',
    value: 'National Beta Club'
  },
  {
    label: 'National Foster Parent Association',
    value: 'National Foster Parent Association'
  },
  {
    label: 'National Junior Classical League',
    value: 'National Junior Classical League'
  },
  {
    label: 'National Roofing Contractors Association',
    value: 'National Roofing Contractors Association'
  },
  {
    label: 'National Slovak Society',
    value: 'National Slovak Society'
  },
  {
    label: 'National Society of Accountants',
    value: 'National Society of Accountants'
  },
  {
    label: 'National Society of the Daughters of the American Revolution (DAR)',
    value: 'National Society of the Daughters of the American Revolution (DAR)'
  },
  {
    label: 'Navy',
    value: 'Navy'
  },
  {
    label: 'NHS',
    value: 'NHS'
  },
  {
    label: 'North Carolina Masonry Contractors Association',
    value: 'North Carolina Masonry Contractors Association'
  },
  {
    label: 'Northeastern Loggers Association',
    value: 'Northeastern Loggers Association'
  },
  {
    label: 'NPCA',
    value: 'NPCA'
  },
  {
    label: 'NPSC',
    value: 'NPSC'
  },
  {
    label: 'Pensacola Sports Association',
    value: 'Pensacola Sports Association'
  },
  {
    label: 'Phi Chi Theta',
    value: 'Phi Chi Theta'
  },
  {
    label: 'Phi Delta Kappa',
    value: 'Phi Delta Kappa'
  },
  {
    label: 'Phi Kappa Phi',
    value: 'Phi Kappa Phi'
  },
  {
    label: 'Phi Kappa Theta National Fraternity',
    value: 'Phi Kappa Theta National Fraternity'
  },
  {
    label: 'Phi Theta Kappa',
    value: 'Phi Theta Kappa'
  },
  {
    label: 'Piedmont Coll',
    value: 'Piedmont Coll'
  },
  {
    label: 'Pony of the Americas Club',
    value: 'Pony of the Americas Club'
  },
  {
    label: 'Refrigerating',
    value: 'Refrigerating'
  },
  {
    label: 'RMCMI',
    value: 'RMCMI'
  },
  {
    label: 'SAT',
    value: 'SAT'
  },
  {
    label: 'SAT Reasoning',
    value: 'SAT Reasoning'
  },
  {
    label: 'SEIU',
    value: 'SEIU'
  },
  {
    label: 'Selection',
    value: 'Selection'
  },
  {
    label: 'SIE',
    value: 'SIE'
  },
  {
    label: 'Sigma Alpha Epsilon',
    value: 'Sigma Alpha Epsilon'
  },
  {
    label: 'Society of Broadcast Engineers',
    value: 'Society of Broadcast Engineers'
  },
  {
    label: 'Society of Women Engineers',
    value: 'Society of Women Engineers'
  },
  {
    label: 'Sons of Norway',
    value: 'Sons of Norway'
  },
  {
    label: 'State Employees Association of North Carolina',
    value: 'State Employees Association of North Carolina'
  },
  {
    label: 'Student California Teachers Association (SCTA)',
    value: 'Student California Teachers Association (SCTA)'
  },
  {
    label: 'SUA',
    value: 'SUA'
  },
  {
    label: 'Teamsters',
    value: 'Teamsters'
  },
  {
    label: 'Tennessee High School',
    value: 'Tennessee High School'
  },
  {
    label: 'the 185th Iowa Air National Guard',
    value: 'the 185th Iowa Air National Guard'
  },
  {
    label: 'the A+ Program',
    value: 'the A+ Program'
  },
  {
    label: 'the Accounting Society',
    value: 'the Accounting Society'
  },
  {
    label: 'The American Legion Squadron',
    value: 'The American Legion Squadron'
  },
  {
    label: 'the American Postal Workers Union',
    value: 'the American Postal Workers Union'
  },
  {
    label: 'the American School',
    value: 'the American School'
  },
  {
    label: 'the American School of Classical Studies',
    value: 'the American School of Classical Studies'
  },
  {
    label: 'the Armed Forces',
    value: 'the Armed Forces'
  },
  {
    label: 'the Block and Bridle Club',
    value: 'the Block and Bridle Club'
  },
  {
    label: 'the Boy Scouts of America',
    value: 'the Boy Scouts of America'
  },
  {
    label: 'the Boys & Girls Clubs of Southwest Washington',
    value: 'the Boys & Girls Clubs of Southwest Washington'
  },
  {
    label: 'the Christian Church (Disciples of Christ',
    value: 'the Christian Church (Disciples of Christ'
  },
  {
    label: 'The Church of Jesus Christ of Latter-day Saints',
    value: 'The Church of Jesus Christ of Latter-day Saints'
  },
  {
    label: 'the Evangelical Lutheran Church of America',
    value: 'the Evangelical Lutheran Church of America'
  },
  {
    label: 'the Glee Club',
    value: 'the Glee Club'
  },
  {
    label: 'the Iowa Federation of Labor',
    value: 'the Iowa Federation of Labor'
  },
  {
    label: 'the Luso-American Education Foundation',
    value: 'the Luso-American Education Foundation'
  },
  {
    label: 'the Lutheran Church-Missouri Synod',
    value: 'the Lutheran Church-Missouri Synod'
  },
  {
    label: 'the Mid-Atlantic',
    value: 'the Mid-Atlantic'
  },
  {
    label: 'the National League for Nursing and CCNE',
    value: 'the National League for Nursing and CCNE'
  },
  {
    label: 'the Pershing Society',
    value: 'the Pershing Society'
  },
  {
    label: 'the Portland Police Association',
    value: 'the Portland Police Association'
  },
  {
    label: 'the Presbyterian Church',
    value: 'the Presbyterian Church'
  },
  {
    label: 'the Presbyterian Church (USA)',
    value: 'the Presbyterian Church (U.S.A.'
  },
  {
    label: 'the Rho Chi Honor Society',
    value: 'the Rho Chi Honor Society'
  },
  {
    label: 'the Seneca Nation of Indians',
    value: 'the Seneca Nation of Indians'
  },
  {
    label: 'the U.S. Navy',
    value: 'the U.S. Navy'
  },
  {
    label: 'the Utility Workers Union of America',
    value: 'the Utility Workers Union of America'
  },
  {
    label: 'U.S. Naval Sea Cadet Corps',
    value: 'U.S. Naval Sea Cadet Corps'
  },
  {
    label: 'UNICO National',
    value: 'UNICO National'
  },
  {
    label: 'University Film and Video Association',
    value: 'University Film and Video Association'
  },
  {
    label: 'USA Water Ski Association',
    value: 'USA Water Ski Association'
  },
  {
    label: 'USBC',
    value: 'USBC'
  },
  {
    label: 'USC Alumni Association',
    value: 'USC Alumni Association'
  },
  {
    label: 'WA Boys and Girls Club',
    value: 'WA Boys and Girls Club'
  },
  {
    label: 'Whirly-Girls',
    value: 'Whirly-Girls'
  },
  {
    label: 'YMCA',
    value: 'YMCA'
  }
];

export {
  careerOptions,
  clubOptions,
  corporationOptions,
  countryOptions,
  genderOptions,
  gradeOptions,
  majorOptions,
  militaryOptions,
  organizationOptions,
  raceOptions,
  religionOptions,
  sportsOptions,
  stateOptions
};
