/* eslint consistent-return: 0 */
import React, { useState } from 'react';
import { Form, Formik } from 'formik';

/**
 * Formik-multi-step wizard example taken from
 * https://github.com/formium/formik/blob/master/examples/MultistepWizard.js
 */
const FormWizard = ({ children, initialValues, onSubmit }) => {
  const [stepNumber, setStepNumber] = useState(0);
  // Convert children of form wizard to an array of child components
  const steps = React.Children.toArray(children);
  // Snapshots of the form state
  const [snapshot, setSnapshot] = useState(initialValues);

  const currentStep = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    // If the form step has an onSubmit function, then run it before
    // preceding to the next step or submitting the form
    if (currentStep.props.onSubmit) {
      await currentStep.props.onSubmit(values, bag);
    }

    // Run submit function for parent form, otherwise move to the next step
    if (isLastStep) {
      return onSubmit(values, bag);
    }
    // Run validation and proceed to the next form step
    bag.setTouched({});
    next(values);
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={currentStep.props.validationSchema}
    >
      {(formik) => (
        <Form>
          {currentStep}
          <div className="form-button-container">
            {stepNumber > 0 && (
              <button
                className="rectangle-button rectangle-button-back"
                onClick={() => previous(formik.values)}
                type="button"
              >
                Back
              </button>
            )}
            <button
              className="rectangle-button rectangle-button-next"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {isLastStep ? 'Submit' : 'Next'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormWizard;
