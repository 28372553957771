import React, { useState } from 'react'
import styled from 'styled-components'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Navbar,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'shards-react'

import logo from '../../assets/EDUrainlogo1.png'

const StyledNavbar = styled(Navbar)`
  width: 100%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 0 7.5rem;
  z-index: 3;
  top: 0;
  position: fixed;

  img {
    height: 100px;
  }
`

const StyledNav = styled(Nav)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

// The function provided as an argument to styled() helps fix a styled
// components bug in which styled-components props pass through the component
// and produce an error
const StyledLink = styled(({ ...props }) => <Link {...props} />)`
  font-family: 'Poppins', 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-decoration: none;

  h3 {
    font-weight: 700;
    font-size: 1.4rem;
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: -0.25rem;
  }

  &:hover {
    h3 {
      color: ${`var(--secondary-color)`};
    }

    p {
      color: ${`var(--secondary-color)`};
    }
  }
`

const StyledMobileLink = styled(({ ...props }) => <StyledLink {...props} />)`
  margin: 0;

  h3 {
    color: ${(props) =>
      props.currentroute === props.linkroute
        ? 'var(--primary-color)'
        : '#6f6f6f'};
  }

  p {
    color: ${(props) =>
      props.currentroute === props.linkroute
        ? 'var(--primary-color)'
        : '#6f6f6f'};
  }
`

const StyledDesktopLink = styled(({ ...props }) => <StyledLink {...props} />)`
  margin: 0 0 0 4rem;

  h3 {
    color: ${(props) =>
      props.currentroute === props.linkroute
        ? 'var(--primary-color)'
        : '#6f6f6f'};
  }

  p {
    color: ${(props) =>
      props.currentroute === props.linkroute
        ? 'var(--primary-color)'
        : '#6f6f6f'};
  }
`

const Header = () => {
  const [dropDownOpen, toggleDropDown] = useState(false)
  const auth = useSelector((state) => state.firebase.auth)
  const location = useLocation()

  const toggle = () => {
    toggleDropDown(!dropDownOpen)
  }

  return (
    <StyledNavbar type="dark" styles="light" expand="true">
      <Link to="/">
        <img src={logo} alt="EDUrain logo." />
      </Link>
      <StyledNav fill>
        <NavItem className="wide-nav">
          <StyledDesktopLink
            to="/"
            currentroute={location.pathname}
            linkroute="/"
          >
            <h3>Dashboard</h3>
          </StyledDesktopLink>
        </NavItem>
        <NavItem className="wide-nav">
          <StyledDesktopLink
            to="/file"
            currentroute={location.pathname}
            linkroute="/file"
          >
            <p>file</p>
            <h3>FAFSA</h3>
          </StyledDesktopLink>
        </NavItem>
        <NavItem className="wide-nav">
          <StyledDesktopLink
            to="/fund"
            currentroute={location.pathname}
            linkroute="/fund"
          >
            <p>fund</p>
            <h3>Scholarships</h3>
          </StyledDesktopLink>
        </NavItem>
        <NavItem className="wide-nav">
          <StyledDesktopLink
            to="/housing"
            currentroute={location.pathname}
            linkroute="/housing"
          >
            <p>find</p>
            <h3>Housing</h3>
          </StyledDesktopLink>
        </NavItem>
        <NavItem className="wide-nav">
          <StyledDesktopLink
            to="/profile"
            currentroute={location.pathname}
            linkroute="/profile"
          >
            {isLoaded(auth) && !isEmpty(auth) ? (
              <h3>Account</h3>
            ) : (
              <h3>Sign In</h3>
            )}
          </StyledDesktopLink>
        </NavItem>
        <Dropdown open={dropDownOpen} toggle={toggle} className="mobile-nav">
          <DropdownToggle>
            <FontAwesomeIcon icon={faBars} />
          </DropdownToggle>

          {isLoaded(auth) && !isEmpty(auth) ? (
            <DropdownMenu right>
              <DropdownItem>
                <StyledMobileLink
                  to="/"
                  currentroute={location.pathname}
                  linkroute="/"
                >
                  <h3>Dashboard</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/profile"
                  currentroute={location.pathname}
                  linkroute="/profile"
                >
                  <h3>Account</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/file"
                  currentroute={location.pathname}
                  linkroute="/file"
                >
                  <p>file</p>
                  <h3>FAFSA</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/fund"
                  currentroute={location.pathname}
                  linkroute="/fund"
                >
                  <p>fund</p>
                  <h3>Scholarships</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/housing"
                  currentroute={location.pathname}
                  linkroute="/housing"
                >
                  <p>find</p>
                  <h3>Housing</h3>
                </StyledMobileLink>
              </DropdownItem>
            </DropdownMenu>
          ) : (
            <DropdownMenu right>
              <DropdownItem>
                <StyledMobileLink
                  to="/"
                  currentroute={location.pathname}
                  linkroute="/"
                >
                  <h3>Dashboard</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/housing"
                  currentroute={location.pathname}
                  linkroute="/housing"
                >
                  <p>find</p>
                  <h3>Housing</h3>
                </StyledMobileLink>
              </DropdownItem>
              <DropdownItem>
                <StyledMobileLink
                  to="/profile"
                  currentroute={location.pathname}
                  linkroute="/profile"
                >
                  <h3>Sign In</h3>
                </StyledMobileLink>
              </DropdownItem>
            </DropdownMenu>
          )}
        </Dropdown>
      </StyledNav>
    </StyledNavbar>
  )
}

export default Header
