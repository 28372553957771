import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import * as Yup from 'yup';
import styled from 'styled-components';
import FormWizard from '../FormWizard';

import { buildAlgoliaQuery } from '../../../utils/buildAlgoliaQuery';
import FindStep1 from './FindStep1';
import FindStep2 from './FindStep2';
import FindStep3 from './FindStep3';
import FindStep4 from './FindStep4';
import Modal from '../../layout/Modal';
import { SET_SEARCH_FILTERS } from '../../../actions/types';

const FindQuestionnaire = ({ open, toggleModal }) => {
  const uid = useSelector((state) => state.firebase.auth.uid);
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firestoreFormRef = firestore.collection('scholarships').doc(uid);
  const modalRef = useRef(null);

  const [initialFormState, setInitialFormState] = useState(null);

  // This is where the Algolia query gets sent/updated
  const onSubmit = (values) => {
    const filterString = buildAlgoliaQuery(values);
    dispatch({
      type: SET_SEARCH_FILTERS,
      payload: filterString
    });
    toggleModal();
    window.location.reload(false);
  };

  const onNewPage = async (values) => {
    modalRef.current.scrollTop = 0;
    const batch = firestore.batch();
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value !== '' && value.length !== 0 && value !== 0) {
        batch.update(firestoreFormRef, { [key]: value });
      }
    });
    await batch.commit();
  };

  useEffect(() => {
    const getFormData = async () => {
      const initialValues = {
        dateBirth: '',
        gender: '',
        race: [],
        gpa: 0,
        gradeLevel: '',
        state: '',
        country: '',
        actScore: 0,
        satScore: 0,
        career: [],
        religion: [],
        major: [],
        clubs: [],
        military: [],
        corporation: [],
        sports: [],
        organization: []
      };
      const firestoreForm = await firestore
        .collection('scholarships')
        .doc(uid)
        .get();
      if (firestoreForm.data() === undefined) {
        await firestore.collection('scholarships').doc(uid).set(initialValues);
        setInitialFormState(initialValues);
      } else {
        setInitialFormState(firestoreForm.data());
      }
    };

    getFormData();
  }, [firestore, uid]);

  return (
    <Modal open={open} toggle={toggleModal}>
      <StyledModalContainer ref={modalRef}>
        {initialFormState ? (
          <>
            <h1>Let&apos;s find more scholarships for you!</h1>
            <FormWizard initialValues={initialFormState} onSubmit={onSubmit}>
              <FindStep1
                onSubmit={onNewPage}
                validationSchema={Yup.object({
                  gpa: Yup.number().min(0).max(4)
                })}
              />
              <FindStep2
                onSubmit={onNewPage}
                validationSchema={Yup.object({
                  actScore: Yup.number().min(0).max(32),
                  satScore: Yup.number().min(0).max(1600)
                })}
              />
              <FindStep3 onSubmit={onNewPage} />
              <FindStep4 onSubmit={onNewPage} />
            </FormWizard>
          </>
        ) : (
          <h5>Loading your form</h5>
        )}
      </StyledModalContainer>
    </Modal>
  );
};

const StyledModalContainer = styled.div`
  min-width: 800px;
  padding: 4rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    min-width: 0;
    padding: 1rem;
  }
`;

export default FindQuestionnaire;
