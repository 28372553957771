import React from 'react';

import { SearchSelect } from '../FormFields';

import {
  clubOptions,
  corporationOptions,
  majorOptions,
  militaryOptions,
  religionOptions
} from './options';

const FindStep3 = () => {
  return (
    <div>
      <SearchSelect
        label="11. What is your religion?"
        name="religion"
        multi
        options={religionOptions}
      />
      <SearchSelect
        label="12. What is your major?"
        name="major"
        multi
        options={majorOptions}
      />
      <SearchSelect
        label="13. What clubs, if any, are you involved in?"
        name="clubs"
        multi
        options={clubOptions}
      />
      <SearchSelect
        label="14. What, if any, affiliations do you have with the military?"
        name="military"
        multi
        options={militaryOptions}
      />
      <SearchSelect
        label="15. Are you or a family member affiliated with a corporation?"
        name="corporation"
        multi
        options={corporationOptions}
      />
    </div>
  );
};

export default FindStep3;
