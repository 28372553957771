import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import FormFieldError from './FormFieldError';

/**
 * FIXME: Keeps changing from controlled to uncontrolled state
 */
const DatePicker = ({ label, name }) => {
  return (
    <StyledDatePicker>
      <label>
        <h6>{label}</h6>
      </label>
      <StyledDateInput component="input" name={name} type="date" />
      <FormFieldError name={name} />
    </StyledDatePicker>
  );
};

const StyledDatePicker = styled.div`
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledDateInput = styled(Field)`
  height: 3rem;
  border: solid 2px #dee2e6;
  -webkit-appearance: none;
  -webkit-min-logical-width: calc(100%);
`;

export default DatePicker;
