import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// CSS Imports. The lower the import, the higher priority that the file has
import 'normalize.css'
import 'shards-ui/dist/css/shards.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/algolia.css'
import './styles/index.scss'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
