import {
  GET_NEXT_FILE_PAGE,
  GET_LAST_FILE_PAGE,
  UPDATE_FILE_RESPONSE,
  GET_FILE_QUESTIONS,
  SUBMIT_FILE_QUESTIONS,
  FILE_ERROR
} from '../actions/types';

import { createFilePages } from '../utils/fileConditionalLogic';

// Pagination currently set to display 5 questions at a time
/*
const initialStateOld = {
  page: 0,
  pageSize: 5,
  lastPage: 0,
  questions: [],
  questionList: [],
  error: null
};
*/
const initialState = {
  currentPage: 0,
  pages: {},
  allQuestions: [],
  error: null
};
// TODO: Add documentation
// TODO: Add question update response state
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILE_QUESTIONS:
      return {
        ...state,
        allQuestions: action.payload.allQuestions,
        pages: action.payload.pages
      };

    case SUBMIT_FILE_QUESTIONS:
      return state;

    // TODO: Get rid of redundant actions
    case GET_NEXT_FILE_PAGE:
    case GET_LAST_FILE_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };

    case UPDATE_FILE_RESPONSE: {
      // Build a new list of questions off of the current list of questions
      // but with the updated question
      const updatedQuestions = state.allQuestions.map((question) => {
        if (question.name === action.payload.name) {
          // These types of questions types use the answer_name key rather than the
          // answer key in their response object
          if (
            action.payload.type === 'select' ||
            action.payload.type === 'multi'
          )
            return {
              ...question,
              response: {
                answer_name: action.payload.answer
              }
            };

          return {
            ...question,
            response: {
              answer: action.payload.answer
            }
          };
        }
        return question;
      });

      // Generate a new pages state object with a utility function that
      // adds in conditionally generated questions are needed
      const newPages = createFilePages(updatedQuestions);

      return {
        ...state,
        allQuestions: updatedQuestions,
        pages: newPages
      };
    }
    case FILE_ERROR:
      return {
        ...state,
        error: String(action.payload)
      };

    default:
      return state;
  }
};
