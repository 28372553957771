/**
 * TODO: Needs to be refactored into a Formik component
 */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, FormInput, ListGroup, ListGroupItem } from 'shards-react';

const StyledFormInput = styled(FormInput)`
  border-radius: 32px;
`;

const StyledSuggestButton = styled(Button)`
  margin: 0 1rem 1rem 0 !important;

  @media (max-width: 800px) {
    padding: 0.25rem 0.5rem !important;
    font-size: 14px;
  }
`;

const StyledOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSearchResultsContainer = styled.div`
  width: 100%;
  z-index: 9;
  position: relative;
  top: -2rem;
`;

const StyledSearchResults = styled(ListGroup)`
  position: absolute;
`;

const StyledSelectedOption = styled(Button)`
  background-color: var(--secondary-color);
  padding: 0.5rem 2rem;
  margin: 0 1rem 1rem 0 !important;
  display: inline;

  @media (max-width: 800px) {
    padding: 0.25rem 0.5rem !important;
    font-size: 14px;
  }
`;

const StyledRemove = styled.span`
  margin-left: 1rem;
`;

const StyledSelectedOptionGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

/**
 * @deprecated and only for use in old non-Formik form system
 */
const MultiSelectLarge = ({ questionAnswer, choices, multi }) => {
  const [userQuery, setUserQuery] = useState('');
  const [queryMatch, setQueryMatch] = useState(choices);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [suggestView, setSuggestView] = useState(false);
  const searchSuggestionRef = useRef(null);

  // Filters the results based on what the user has typed in
  const queryResults = (query) => {
    const matches = choices.filter((element) => {
      return element.data.toLowerCase().includes(query.toLowerCase());
    });

    const priorityMatch = matches.filter((element) => {
      return (
        element.data.toLowerCase().slice(0, query.length) ===
        query.toLowerCase()
      );
    });

    const otherMatch = matches.filter((element) => {
      return (
        element.data.toLowerCase().slice(0, query.length) !==
        query.toLowerCase()
      );
    });

    setQueryMatch([...priorityMatch, ...otherMatch]);
  };

  const handleChange = (event) => {
    setUserQuery(event.target.value);
    queryResults(event.target.value);
  };

  // This function runs when the user has clicked on a suggested option, adding
  // it to the selectedChoices list and updating the redux state with the
  // response name
  const fillSuggestion = (choice) => {
    const selectedChoiceNames = selectedChoices.map((element) => element.name);

    // Just hide the search suggestions if the user clicks on an answer
    // they've already selected
    if (selectedChoiceNames.includes(choice.name)) {
      setSuggestView(false);
      return;
    }

    // Upon filling suggestion, reset the search results
    setUserQuery('');
    setQueryMatch(choices);

    const newChoices = multi ? [...setSelectedChoices, choice] : [choice];
    setSelectedChoices(newChoices);
    setSuggestView(false);
  };

  const removeOption = (event) => {
    event.preventDefault();
    const newChoices = selectedChoices.filter(
      (element) => element.name !== event.target.id
    );

    setSelectedChoices(newChoices);
  };

  const keyPressHandler = (event) => {
    // Prevent the enter key from causing a browser refresh
    if (event.key === 'Enter') event.preventDefault();
  };

  // Should only run once since we are just auto-filling responses from
  // the backend in this useEffect hook
  useEffect(() => {
    let newSelectedChoices = [];

    // Add event listener that closes the search suggestions if the
    // user clicks outside of it
    const outsideClickHandler = (event) => {
      if (
        searchSuggestionRef.current &&
        !searchSuggestionRef.current.contains(event.target)
      ) {
        setSuggestView(false);
      }
    };

    document.addEventListener('mousedown', outsideClickHandler);

    if (questionAnswer) {
      newSelectedChoices = choices.filter((element) => {
        return questionAnswer.includes(element.name);
      });
    }

    setSelectedChoices(newSelectedChoices);

    // Cleanup function for evet listener
    return () => {
      document.removeEventListener('mousedown', outsideClickHandler);
    };
  }, []); // eslint-disable-line

  const searchSuggestionList = queryMatch.map((element) => (
    <ListGroupItem onClick={() => fillSuggestion(element)} key={element.name}>
      {element.name}
    </ListGroupItem>
  ));

  const searchSuggestionButtons = queryMatch.map((element) => (
    <StyledSuggestButton
      pill
      outline
      className="pill-button"
      onClick={() => fillSuggestion(element)}
      key={element.name}
    >
      {element.name}
    </StyledSuggestButton>
  ));

  const selectOptionsGroup = selectedChoices.map((element) => (
    <StyledSelectedOption
      pill
      key={element.name}
      id={element.name}
      onClick={removeOption}
    >
      {element.name}{' '}
      <StyledRemove id={element.name} onClick={removeOption}>
        Remove
      </StyledRemove>
    </StyledSelectedOption>
  ));

  return (
    <div>
      <StyledFormInput
        value={userQuery}
        onChange={handleChange}
        onFocus={() => setSuggestView(true)}
        onKeyPress={keyPressHandler}
      />
      <StyledOptions>
        <h6>CLICK ALL THAT APPLY</h6>
        {suggestView ? (
          <StyledSearchResultsContainer ref={searchSuggestionRef}>
            <StyledSearchResults>{searchSuggestionList}</StyledSearchResults>
          </StyledSearchResultsContainer>
        ) : (
          <div />
        )}
        <div>{searchSuggestionButtons}</div>
        {selectedChoices.length > 0 ? (
          <StyledSelectedOptionGroup>
            {selectOptionsGroup}
          </StyledSelectedOptionGroup>
        ) : (
          <div />
        )}
      </StyledOptions>
    </div>
  );
};

export default MultiSelectLarge;
