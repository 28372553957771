/* eslint no-underscore-dangle: 0 */
// Elasticsearch returns variables with underscores
import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';

// import classNames from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
// import { faSquare } from '@fortawesome/free-regular-svg-icons';

// import { SET_MATCHED_ONLY } from '../../../../actions/types';

import ELKSearchbox from '../ELKSearchBox';
import ELKRefinementList from '../ELKRefinementList';
import ELKCard from '../ELKCard';
import '../../ScholarshipSearch/styles.scss';

const ScholarshipHits = ({ hits, filter }) => {
  return (
    <>
      {hits.map((hit) =>
        filter === '' ||
        hit._source.Description.toLowerCase().includes(filter.toLowerCase()) ||
        hit._source.Name.toLowerCase().includes(filter.toLowerCase()) ||
        hit._source.Major.toLowerCase().includes(filter.toLowerCase()) ||
        hit._source.City.toLowerCase().includes(filter.toLowerCase()) ||
        hit._source.State.toLowerCase().includes(filter.toLowerCase()) ? (
          <ELKCard hit={hit} key={hit._id} />
        ) : null
      )}
    </>
  );
};

const ELKComponent = ({ hits }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // const dispatch = useDispatch();

  // const toggleMatchedOnly = () => {
  //   dispatch({
  //     type: SET_MATCHED_ONLY,
  //     payload: !showMatchedOnly
  //   });
  // };

  return (
    <div>
      <ELKSearchbox currentRefinement={searchTerm} refine={setSearchTerm} />
      <div className="ScholarshipSearch__filters">
        <ELKRefinementList
          attribute="Major"
          items={hits}
          currentRefinement={searchTerm}
          refine={setSearchTerm}
        />
        {/* <ELKRefinementList
          attribute="Race"
          items={hits}
          currentRefinement={searchTerm}
          refine={setSearchTerm}
        /> */}
        {/* <ELKRefinementList
          attribute="City"
          items={hits}
          currentRefinement={searchTerm}
          refine={setSearchTerm}
        /> */}
        <ELKRefinementList
          attribute="State"
          items={hits}
          currentRefinement={searchTerm}
          refine={setSearchTerm}
        />
        {/* <ELKRefinementList
          attribute="Country"
          items={hits}
          currentRefinement={searchTerm}
          refine={setSearchTerm}
        /> */}

        {/* <button
          type="button"
          className={classNames('ScholarshipSearch__match-button', {
            'ScholarshipSearch__match-button--on': showMatchedOnly
          })}
          onClick={toggleMatchedOnly}
        >
          <p>Matched Only:</p>
          {showMatchedOnly ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faSquare} />
          )}
        </button> */}
      </div>
      <ScholarshipHits hits={hits} filter={searchTerm} />
    </div>
  );
};

export default ELKComponent;
