import React, { useState } from 'react';
import styled from 'styled-components';
import { useFirebase } from 'react-redux-firebase';
import { Link, useHistory } from 'react-router-dom';
import {
  Alert,
  Container,
  Form,
  FormGroup,
  FormInput,
  Button
} from 'shards-react';

import ForgotPasswordModal from '../components/auth/ForgotPasswordModal';

const StyledPasswordReset = styled.button`
  background: none;
  border: 0;
`;

const SignInPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const firebase = useFirebase();
  const history = useHistory();

  const changeHandler = ({ currentTarget: { id, value } }) => {
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const signIn = (event) => {
    event.preventDefault();

    firebase
      .login({
        // Firebase will hash passwords automatically
        email,
        password
      })
      .then(() => {
        setTimeout(() => history.push('/'), 500);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  return (
    <Container className="card form-container">
      <h1>Login Page</h1>
      <Form className="form-body">
        {errorMessage ? (
          <Alert theme="warning">
            <p>{errorMessage}</p>
          </Alert>
        ) : null}
        <FormGroup>
          <label htmlFor="email">Email</label>
          <FormInput
            id="email"
            type="email"
            placeholder="example@mail.com"
            value={email}
            onChange={changeHandler}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="password">Password</label>
          <FormInput
            id="password"
            type="password"
            value={password}
            onChange={changeHandler}
          />
        </FormGroup>
        <Button pill outline className="pill-button" onClick={signIn}>
          Log in
        </Button>
        <div className="signup-login-option">
          <h6>Need to make an account?</h6>
          <Link to="/signup">
            <h5>SIGN UP</h5>
          </Link>
          <h6>Forgot your password?</h6>
          <StyledPasswordReset
            type="button"
            onClick={() => setOpenPasswordModal(true)}
          >
            <h5>RESET PASSWORD</h5>
          </StyledPasswordReset>
        </div>
      </Form>
      <ForgotPasswordModal
        open={openPasswordModal}
        toggleModal={() => setOpenPasswordModal(!openPasswordModal)}
      />
    </Container>
  );
};

export default SignInPage;
