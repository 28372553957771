import React from 'react';

import { SearchSelect } from '../FormFields';

import { organizationOptions, sportsOptions } from './options';

const FindStep4 = () => {
  return (
    <div>
      <SearchSelect
        label="16. What sports do you play?"
        name="sports"
        multi
        options={sportsOptions}
      />
      <SearchSelect
        label="17. Are you part of any of these organizations, or are you a child of someone who is?"
        name="organization"
        multi
        options={organizationOptions}
      />
    </div>
  );
};

export default FindStep4;
