import React from 'react';
import styled from 'styled-components';
import { Button, Card, Container } from 'shards-react';

const StyledErrorContainer = styled(Container)`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledErrorCard = styled(Card)`
  max-width: 800px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledErrorBlock = styled.div`
  margin: 1rem;
  padding: 1rem;
  background-color: #2f3466;
  border-radius: 8px;
  color: #fff;

  p {
    font-family: 'Courier New', Courier, monospace;
  }
`;

const ErrorFallback = ({ error, resetError }) => (
  <StyledErrorContainer>
    <StyledErrorCard className="card">
      <h5>Sorry, an error has occured.</h5>
      <StyledErrorBlock>
        <p>{error.toString()}</p>
      </StyledErrorBlock>
      <p>
        If this error keeps popping up, please send an email to info@edurain.org
      </p>
      <Button
        pill
        outline
        className="pill-button"
        onClick={() => {
          resetError();
        }}
      >
        Report feedback
      </Button>
    </StyledErrorCard>
  </StyledErrorContainer>
);

export default ErrorFallback;
