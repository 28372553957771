/* eslint no-underscore-dangle: 0 */
// Algolia API results use underscore variables
import React from 'react';
// import { Highlight } from 'react-instantsearch-dom';

import AwardComponent from './AwardComponent';
import BadgeRow from './BadgeRow';
import { displayedAttributes } from './displayedAttributes';
import Modal from '../../layout/Modal';
import './styles.scss';

const ScholarshipModal = ({ open, toggle, hit }) => {
  return (
    <Modal open={open} toggle={toggle}>
      <div className="ScholarshipCard ScholarshipCard__modal--open">
        <h4>
          {/* <Highlight attribute="Name" hit={hit} /> */}
          <p>{hit._source.Name}</p>
        </h4>
        <div className="ScholarshipCard__award-deadline">
          <div className="ScholarshipCard__badge">
            <AwardComponent
              avgAward={hit._source.AverageAward}
              minAward={hit._source.MinAward}
              maxAward={hit._source.MaxAward}
              hit={hit}
            />
          </div>
          <div className="ScholarshipCard__modal-deadline">
            <p className="ScholarshipCard__deadline-label">Due Date</p>
            {/* <Highlight
              className="ScholarshipCard__deadline
              ScholarshipCard__deadline--modal"
              attribute="Deadline"
              hit={hit}
            /> */}
            <p
              className="ScholarshipCard__deadline
              ScholarshipCard__deadline--modal"
            >
              {hit._source.Deadline}
            </p>
          </div>
        </div>
        <div className="ScholarshipCard__desc">
          {/* <Highlight attribute="Description" hit={hit} /> */}
          <p>{hit._source.Description}</p>
        </div>
        <div className="ScholarshipCard__bottom-row">
          <BadgeRow
            displayedAttributes={displayedAttributes}
            hit={hit}
            limit={10}
            hideMulti={false}
          />
          <a href={hit._source.Url} target="blank">
            <div className="ScholarshipCard__apply">Apply</div>
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ScholarshipModal;
