import React from 'react';
import { Button } from 'shards-react';

const Pagination = ({ currentPage, setCurrentPage, totalScholarships }) => (
  <div style={{ display: 'flex' }}>
    {currentPage <= 1 ? (
      <>
        <Button
          onClick={() => setCurrentPage(0)}
          outline
          theme={currentPage === 0 ? 'info' : 'primary'}
        >
          Page 1
        </Button>
        <Button
          onClick={() => setCurrentPage(1)}
          outline
          theme={currentPage === 1 ? 'info' : 'primary'}
        >
          Page 2
        </Button>
        <Button
          onClick={() => setCurrentPage(2)}
          outline
          theme={currentPage === 2 ? 'info' : 'primary'}
        >
          Page 3
        </Button>
        <p>...</p>
        <Button outline>{Math.floor(totalScholarships / 100)}</Button>
      </>
    ) : (
      <>
        <Button outline onClick={() => setCurrentPage(0)}>
          Page 1
        </Button>
        <p>...</p>
        <Button outline onClick={() => setCurrentPage(currentPage - 1)}>
          Page {currentPage}
        </Button>
        <Button>Page {currentPage + 1}</Button>
        <Button outline onClick={() => setCurrentPage(currentPage + 1)}>
          Page {currentPage + 2}
        </Button>
        <p>...</p>
        <Button outline>Page {Math.floor(totalScholarships / 100)}</Button>
      </>
    )}
  </div>
);

export default Pagination;
