/* eslint no-underscore-dangle: 0 */
// Disable eslint rule since underscore dangle is part of Algolia API that we
// can't change
import React, { useState } from 'react';

import './styles.scss';

// Checks for the attribute in the highlighted results, then it checks
// for the attribute in the regular Algolia hit object
const getAttribute = (attribute, hit) => {
  // TODO: difference between hit._highlightResult[]
  //                  and hit.highlightResult[].value
  // const result =
  //   (hit._highlightResult[attribute] &&
  //     hit._highlightResult[attribute].value) ||
  //   hit[attribute];
  const result = hit._source[attribute];

  return result;
};

const Badge = ({ attribute, hit, hideMulti }) => {
  let result = getAttribute(attribute, hit);
  // EnrollementLevel is returned as a string from Elasticsearch
  if (attribute === 'EnrollmentLevel') result = result.split(',');

  const multipleResults = typeof result === 'object' && result.length > 1;
  const [resultDisplay, setResultDisplay] = useState(false);

  const onHover = () => {
    if (multipleResults) setResultDisplay(true);
  };

  const onLeave = () => {
    if (multipleResults) setResultDisplay(false);
  };

  return (
    <div onMouseEnter={onHover} onMouseLeave={onLeave}>
      <div className="ScholarshipCard__badge ScholarshipCard__badge--secondary">
        {hideMulti ? (
          <p>
            {attribute}: {multipleResults ? 'Multiple' : result}
            {Number.isNaN(parseInt(result, 10)) ? null : '+'}
          </p>
        ) : (
          <p>
            {attribute}: {multipleResults ? result.join(', ') : result}
            {Number.isNaN(parseInt(result, 10)) ? null : '+'}
          </p>
        )}
      </div>
      {resultDisplay && hideMulti ? (
        <div className="ScholarshipCard__badge-popup-container">
          <div className="ScholarshipCard__badge-popup">
            {result.join(', ')}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const BadgeRow = ({ displayedAttributes, hit, limit, hideMulti }) => {
  const filteredAttributes = displayedAttributes.filter((attribute) => {
    const result = getAttribute(attribute, hit);

    /**
     * Don't render a badge for attributes that include non null values
     * The current Algolia index has quite a few ways to represent null
     * values, so the statement below attempts to capture all those
     */
    if (['', 'none', '0', 0].includes(result)) {
      return false;
    }
    if (typeof result === 'object' && result[0] === 'none') {
      return false;
    }
    return true;
  });

  let badges = filteredAttributes.map((attribute) => (
    <Badge
      attribute={attribute}
      hit={hit}
      hideMulti={hideMulti}
      key={attribute}
    />
  ));

  // If the limit prop is provided, we limit the amount of badges
  if (limit) badges = badges.slice(0, limit);

  return <div className="ScholarshipCard__badge-row">{badges}</div>;
};

export default BadgeRow;
