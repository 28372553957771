import React from 'react';
import styled from 'styled-components';
import { Container } from 'shards-react';

const StyledFundPage = styled(Container)`
  max-width: 800px;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    margin-bottom: 2rem;
  }

  h5 {
    margin-bottom: 1rem;
  }
`;

const File = () => {
  return (
    <StyledFundPage className="card">
      <h1>File is currently undergoing a few important upgrades.</h1>
      <h5>
        We are adding major feature updates and making sure that our platform
        adapts to any changes to the FAFSA for this year. We will have the easy
        FAFSA filing platform up by October 1st of this year, when the actual
        FAFSA application form pops up.
      </h5>
      <h5>
        It will be here soon though, so visit{' '}
        <a href="https://edurain.org/" target="blank">
          our website to learn more!
        </a>
      </h5>
    </StyledFundPage>
  );
};

export default File;
