/**
 * This list controls every attribute that'll be shown when a user clicks
 * on a scholarship card, thus opening the modal. The scholarship card
 * will only show the first three attributes if they contain a non-null value
 */
export const displayedAttributes = [
  'GPA',
  'Race',
  'Ethnicity',
  'Religion',
  'Club',
  'Sport',
  'EnrollmentLevel',
  'CareerInterest',
  'CorpAffiliation',
  'Organization',
  'State'
];
