import React from 'react';
import styled from 'styled-components';
import { Container } from 'shards-react';

const Styled404Container = styled(Container)`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoPage404 = () => {
  return (
    <Styled404Container>
      <h1>404 Not found</h1>
      <h6>
        It looks like the page that you&apos;re trying to reach doesn&apos;t
        exist.
      </h6>
    </Styled404Container>
  );
};

export default NoPage404;
