import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const StyledSearchForm = styled.form`
  position: relative;

  label {
    width: 100%;
  }

  input {
    width: 100%;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    border: solid 2px #e6e8ed;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    color: #5c5c5c;
    position: absolute;
    top: 0.9rem;
    left: 1rem;
  }
`;

const ELKSearchbox = ({ currentRefinement, refine }) => {
  return (
    <StyledSearchForm
      noValidate
      action=""
      role="search"
      onSubmit={(e) => e.preventDefault()}
    >
      <label>
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="search"
          value={currentRefinement}
          onChange={(event) => refine(event.target.value)}
        />
      </label>
    </StyledSearchForm>
  );
};

export default ELKSearchbox;
