import React, { useState, useEffect, useRef } from 'react';
import { useField } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const SearchSelect = ({ label, name, multi, options }) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const [userQuery, setUserQuery] = useState('');
  const [queryMatch, setQueryMatch] = useState(options);
  const [suggestView, setSuggestView] = useState(false);
  const searchSuggestionRef = useRef(null);

  // Filters the results based on what the user has typed in
  const queryResults = (query) => {
    const normalizedQuery = query.toLowerCase();
    const matches = options.filter((option) => {
      return option.value.toLowerCase().includes(normalizedQuery);
    });

    const priorityMatch = matches.filter((option) => {
      return (
        option.value.toLowerCase().slice(0, query.length) === normalizedQuery
      );
    });

    const otherMatch = matches.filter((option) => {
      return (
        option.value.toLowerCase().slice(0, query.length) !== normalizedQuery
      );
    });

    setQueryMatch([...priorityMatch, ...otherMatch]);
  };

  const handleChange = (event) => {
    setUserQuery(event.target.value);
    queryResults(event.target.value);
  };

  const fillSuggestion = (choice) => {
    const selectedChoiceNames = value.map((valueItem) => valueItem.label);

    if (selectedChoiceNames.includes(choice.label)) {
      setSuggestView(false);
      return;
    }

    setUserQuery('');
    setQueryMatch(options);

    const newChoices = multi ? [...value, choice] : [choice];
    setValue(newChoices);
    setSuggestView(false);
  };

  const removeOption = (event) => {
    const newChoices = value.filter(
      (valueItem) => valueItem.label !== event.target.id
    );
    setValue(newChoices);
  };

  // Prevent the enter key from causing a browser refresh
  const keyPressHandler = (event) => {
    if (event.key === 'Enter') event.preventDefault();
  };

  useEffect(() => {
    const outsideClickHandler = (event) => {
      if (
        searchSuggestionRef.current &&
        !searchSuggestionRef.current.contains(event.target)
      ) {
        setSuggestView(false);
      }
    };
    document.addEventListener('mousedown', outsideClickHandler);
    return () => {
      document.removeEventListener('mousedown', outsideClickHandler);
    };
  }, []);

  const searchSuggestions = queryMatch.map((option) => (
    <StyledSuggestionItem
      onClick={() => fillSuggestion(option)}
      key={option.label}
    >
      <p>{option.label}</p>
    </StyledSuggestionItem>
  ));

  const selectOptionsGroup = value.map((option) => (
    <StyledSelectedOption key={option.label}>
      <p>{option.label}</p>
      <StyledRemoveButton
        icon={faTimes}
        onClick={removeOption}
        id={option.label}
      />
    </StyledSelectedOption>
  ));

  return (
    <StyledSearchSelect>
      <label>
        <h6>{label}</h6>
      </label>
      <StyledInput
        value={userQuery}
        onChange={handleChange}
        onFocus={() => setSuggestView(true)}
        onKeyPress={keyPressHandler}
        placeholder="Search"
      />
      {suggestView ? (
        <StyledSearchResultsContainer ref={searchSuggestionRef}>
          <StyledSearchResults>{searchSuggestions}</StyledSearchResults>
        </StyledSearchResultsContainer>
      ) : null}
      {value.length > 0 ? (
        <StyledSelectedOptionGroup>
          <h6>Selected Options</h6>
          {selectOptionsGroup}
        </StyledSelectedOptionGroup>
      ) : null}
    </StyledSearchSelect>
  );
};

const StyledSearchSelect = styled.div`
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  height: 3rem;
  margin-bottom: 1rem;
  border: solid 2px #dee2e6;
`;

const StyledSearchResultsContainer = styled.div`
  z-index: 9;
  position: relative;
  top: -1rem;
`;

const StyledSearchResults = styled.div`
  max-height: 50vh;
  position: absolute;
  overflow-y: auto;
  background: #fff;
  border: solid 2px #dee2e6;
`;

const StyledSuggestionItem = styled.div`
  padding: 1rem;
  display: flex;

  p {
    margin: auto 0;
  }
`;

const StyledSelectedOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledRemoveButton = styled(FontAwesomeIcon)`
  color: #757575;
  font-size: 1.25rem;
`;

const StyledSelectedOptionGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export default SearchSelect;
