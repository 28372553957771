import React from 'react';
// import { Highlight } from 'react-instantsearch-dom';
// const AwardComponent = ({ avgAward, minAward, maxAward, hit }) => {
const AwardComponent = ({ avgAward, minAward, maxAward }) => {
  if (avgAward !== 0) {
    // <p>
    //   $<Highlight attribute="AverageAward" hit={hit} />
    // </p>
    return <p>${avgAward}</p>;
  }
  if (maxAward !== 0) {
    // <p>
    //   $<Highlight attribute="MaxAward" hit={hit} />
    // </p>
    return <p>${maxAward}</p>;
  }
  if (minAward !== 0) {
    // <p>
    //   $<Highlight attribute="MinAward" hit={hit} />
    // </p>
    return <p>${minAward}</p>;
  }
  return <p>Award varies</p>;
};

export default AwardComponent;
