import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const middleware = [thunk];
// Passing in this empty function will prevent errors on browsers
// that don't have redux-devtools installed when running in dev mode
const noDevToolsBackup = (e) => e;

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    composeWithDevTools() || noDevToolsBackup
  )
);

export default store;
