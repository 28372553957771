import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Alert,
  Form,
  FormGroup,
  FormInput,
  Button,
  Progress
} from 'shards-react';

import { validatePassword } from '../utils/validatePassword';
import signUpGraphic from '../assets/signup-graphic.png';

const StyledSignUpPage = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 600px;
    margin-left: 4rem;
  }

  @media (min-width: 2000px) {
    img {
      height: 80vh;
      margin-left: 256px;
    }
  }
`;

// TODO: For production, make sure we send a verification email upon signup
// TODO: Consider using Recaptcha to prevent spam signins/signups
const SignUpPage = () => {
  // Local email/password state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  // Local password validation state
  const [validationObj, setValidationObj] = useState({});
  const [validationStrength, setValidationStrength] = useState(0);
  // Local error state message
  const [errorMessage, setErrorMessage] = useState(null);

  const firebase = useFirebase();
  const history = useHistory();

  const changeHandler = ({ currentTarget: { id, value } }) => {
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    } else if (id === 'repeatPassword') {
      setRepeatPassword(value);
    }
  };

  useEffect(() => {
    // Run the password validation check to see if password meets requirements
    const validationCheck = validatePassword(password);
    setValidationObj(validationCheck);

    // Builds a validation strength score from 0 to 4 with 4 being fully valid
    let currentValidation = 0;
    Object.keys(validationCheck).forEach((check) => {
      if (validationCheck[check]) currentValidation += 1;
    });
    setValidationStrength(currentValidation);
  }, [password]);

  const signUp = (event) => {
    event.preventDefault();

    if (
      validationStrength === Object.keys(validationObj).length &&
      password === repeatPassword
    ) {
      firebase
        .createUser(
          {
            // Firebase will hash passwords automatically
            email,
            password
          },
          {
            // Profile information
            email
          }
        )
        .then(() => {
          setTimeout(() => history.push('/'), 500);
        })
        .catch((error) => {
          if (error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(
              'Server error. Please try again later and contact support.'
            );
          }
        });
    } else if (validationStrength !== Object.keys(validationObj).length) {
      setErrorMessage(
        'Make sure your password meets the password requirements!'
      );
    } else if (password !== repeatPassword) {
      setErrorMessage(
        'Make sure your password and repeat password match each other!'
      );
    } else {
      setErrorMessage('Unknown error, please contact support');
    }
  };

  return (
    <StyledSignUpPage>
      <div className="card form-container">
        <h1>Get started</h1>
        <h5>Create your EDUrain account</h5>
        <Form className="form-body">
          {errorMessage ? (
            <Alert theme="warning" fade={false}>
              <p>{errorMessage}</p>
            </Alert>
          ) : null}
          <FormGroup>
            <label htmlFor="email">Email</label>
            <FormInput
              id="email"
              type="email"
              placeholder="example@mail.com"
              value={email}
              onChange={changeHandler}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="password">Password</label>
            <FormInput
              id="password"
              type="password"
              value={password}
              onChange={changeHandler}
            />
            <Progress
              bar
              theme="success"
              value={
                (validationStrength / Object.keys(validationObj).length) * 100
              }
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="repeatPassword">Repeat Password</label>
            <FormInput
              id="repeatPassword"
              type="password"
              value={repeatPassword}
              onChange={changeHandler}
            />
          </FormGroup>
          <p>
            {validationObj.passwordLength ? '✔️' : '❌'} Must be greater than 5
            characters
          </p>
          <p>
            {validationObj.upperCase ? '✔️' : '❌'} Must contain an uppercase
            letter
          </p>
          <p>
            {validationObj.number ? '✔️' : '❌'} Must contain a number from 0-9
          </p>
          <p>
            {validationObj.specialChar ? '✔️' : '❌'} Must contain a special
            character from ! @ # $ % ^ & * - + = _ , : ; .
          </p>
          <p>
            {password && password === repeatPassword ? '✔️' : '❌'} Passwords
            are the same
          </p>
          <div className="button-container">
            <Button pill outline className="pill-button" onClick={signUp}>
              Sign Up with Email
            </Button>
          </div>
          <div className="signup-login-option">
            <h6>Already have an account?</h6>
            <Link to="/login">
              <h5>LOGIN</h5>
            </Link>
          </div>
        </Form>
      </div>
      <img src={signUpGraphic} alt="Signup illustration" className="wide-nav" />
    </StyledSignUpPage>
  );
};

export default SignUpPage;
