import {
  FIND_ERROR,
  SET_SEARCH_FILTERS,
  SET_MATCHED_ONLY
} from '../actions/types';

const initialSearchFilter = localStorage.getItem('searchFilter') || '';

const initialState = {
  searchFilter: initialSearchFilter,
  showMatchedOnly: true,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilter: action.payload
      };

    case SET_MATCHED_ONLY:
      return {
        ...state,
        showMatchedOnly: action.payload
      };

    case FIND_ERROR:
      return {
        ...state,
        error: String(action.payload)
      };

    default:
      return state;
  }
};
