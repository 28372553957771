import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './ErrorFallback';

/**
 * Provides a default fallback UI. For more specific error handling, try
 * using the ErrorBoundary component from 'react-error-boundary'
 */
const GenericErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorFallback error={error} resetError={resetErrorBoundary} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GenericErrorBoundary;
