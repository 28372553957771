import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from '../styles/dashboard/dashboard.module.css'
import FeaturedHouse from '../components/dashboard/FeaturedHouse'

import prop7222 from '../assets/dashboard/featured-housing/7222-forsyth.jpeg'
import prop7218 from '../assets/dashboard/featured-housing/7218-forsyth.jpeg'
import prop7212 from '../assets/dashboard/featured-housing/7212-forsyth.jpeg'

const Dashboard = () => {
  const history = useHistory()

  const featuredProps = [
    {
      address: '7222 Forsyth',
      photo: prop7222,
      price: 2795,
      beds: 2,
      baths: 2,
      sqft: 1150,
      contact: '314-277-3508',
      description: `Hardwood floors throughout, Central air/heat, New windows, Updated kitchens equipped with dishwashers and microwaves, 2 newly remodeled, full bathrooms per unit, New windows, In-unit washers and dryers, Pet free and smoke free, Free basement storage and off street parking, Common area cleaned and maintained by professional services.`
    },
    {
      address: '7218 Forsyth',
      photo: prop7218,
      price: 2995,
      beds: 2,
      baths: 2,
      sqft: 1150,
      contact: '314-277-3508',
      description: `Available June 1, reserve now! These units have been fully rehabbed. Each 2 bedroom/2 bath unit offers 1150 feet of upscale living space. Featuring an open floor plan, beautiful kitchen with upscale appliances/stone countertops, new bathrooms, central heat and AC, hardwood floors throughout, in-unit washer/dryer, smoke free environment, free off-street parking and storage in basement, and located just a stone's throw away from Wash U`
    },
    {
      address: '7212 Forsyth',
      photo: prop7212,
      price: 2395,
      beds: 2,
      baths: 1,
      contact: '314-277-3508',
      description: `There are beautiful arched entrances and stained glass windows as well as hardwood floors an UPDATED kitchen, central AC and extra bonus room for more space! This unit features a wood fireplace, free parking, basement storage and coin-op washers and dryers! Blocks from the Metrolink. Close to WashU, BJC, SLU, and downtown Clayton! Pet free & smoke free.`
    }
  ]

  return (
    <main id={styles.main_dashboard}>
      <div className={styles.dashboard_section}>
        <section className={styles.main_card}>
          <h2>Housing</h2>
          <h3 id={styles.featured_text}>Featured Properties</h3>
          {featuredProps.map((prop, index) => {
            return <FeaturedHouse key={index} {...prop} />
          })}
        </section>
        <section className={styles.main_card}>
          <h2>FAFSA</h2>
          <p>
            We are adding major feature updates and making sure that our
            platform adapts to any changes to the FAFSA for this year. We will
            have the easy FAFSA filing platform up by October 1st of this year,
            when the actual FAFSA application form pops up.
          </p>
        </section>
      </div>
      <div className={styles.dashboard_section}>
        <section className={styles.main_card}>
          <h2>Scholarships</h2>
          <p id={styles.questionaire_text}>
            Fill out the questionnaire to get matched with scholarships.
          </p>
          <button
            onClick={() => {
              history.push('/fund')
            }}
          >
            Get Started
          </button>
        </section>
      </div>
    </main>
  )
}

export default Dashboard
