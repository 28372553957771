import React from 'react';

import { DropDownSelect, NumberField, SearchSelect } from '../FormFields';

import { careerOptions, countryOptions, stateOptions } from './options';

const FindStep2 = () => {
  return (
    <div>
      <DropDownSelect
        label="6. What's your state?"
        name="state"
        options={stateOptions}
      />
      <DropDownSelect
        label="7. What's your country?"
        name="country"
        options={countryOptions}
      />
      <NumberField
        label="8. What's your ACT score?"
        name="actScore"
        min={0}
        max={32}
        step={1}
      />
      <NumberField
        label="9. What's your SAT score?"
        name="satScore"
        min={0}
        max={1600}
        step={1}
      />
      <SearchSelect
        label="10. What career do you intend to pursue?"
        name="career"
        multi
        options={careerOptions}
      />
    </div>
  );
};

export default FindStep2;
