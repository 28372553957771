import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from '../../../styles/housing/housing-cards.module.css'

const HousingCards = ({ rentals, clickDetail }) => {
  const PAGE_SIZE = 10
  const [page, setPage] = useState(0)

  return (
    <section id={styles.housing_cards_section}>
      {rentals
        .slice(page * PAGE_SIZE, PAGE_SIZE * page + PAGE_SIZE) // TODO: CHECK FOR OUT OF BOUNDS
        .map((rental, index) => {
          let fullAddress = `${rental.address} 
        ${rental.StreetSuffix}, ${rental.City}, ${rental.StateOrProvince} ${rental.PostalCode}`
          fullAddress = fullAddress.replaceAll(/undefined,/g, '')
          return (
            <div
              key={`${index}_${rental.address}`}
              className={styles.housing_card}
              onClick={clickDetail.bind(this, rental.ListingId)}
            >
              <img
                className={styles.housing_card_image}
                src={rental.photos[0].MediaURL}
                alt={`${rental.address} image`}
                referrerPolicy="no-referrer"
              />
              <div className={styles.housing_card_info}>
                <h3 className={styles.housing_info_header}>
                  {rental.price_min}$/mo
                </h3>
                <p>
                  {rental.beds_max} beds | {rental.baths_max} baths
                  {rental.SquareFeet ? ` | ${rental.SquareFeet} sqft` : ''}
                </p>
                <p>{fullAddress}</p>
                <p className={styles.housing_sub_text}>{rental.AgentName}</p>
              </div>
            </div>
          )
        })}
      {rentals.length > 0 ? (
        <section id={styles.page_section}>
          {page > 0 ? (
            <button onClick={(_) => setPage(page - 1)}>Back</button>
          ) : null}
          <p>page {page + 1}</p>
          {page < Math.ceil(rentals.length / PAGE_SIZE) - 1 ? (
            <button onClick={(_) => setPage(page + 1)}>Next</button>
          ) : null}
        </section>
      ) : (
        <></>
      )}
    </section>
  )
}

export default HousingCards
