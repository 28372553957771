/* eslint no-underscore-dangle: 0 */
// Elasticsearch returns variables with underscores
import React, { useState, useEffect, useRef } from 'react';
// import { connectRefinementList } from 'react-instantsearch-dom';
import { orderBy } from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import '../../CustomRefinementList/styles.scss';

const ELKRefinementList = ({
  attribute,
  // currentRefinement,
  items,
  refine
  //   searchForItems
}) => {
  const [open, setOpen] = useState(false);
  const [itemState, setItemState] = useState(items);
  const filterRef = useRef(null);
  const dropDownRef = useRef(null);

  const toggleList = () => {
    setOpen(!open);
  };

  //   const filterSearch = (event) => {
  //     searchForItems(event.target.value);
  //   };

  const addFilter = (event, item) => {
    event.preventDefault();
    refine(item._source[attribute]);
  };

  useEffect(() => {
    // Add event listener that closes the dropdown menu if the
    // user clicks outside of the dropdown or the filter button
    const outsideClickHandler = (event) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target) &&
        !filterRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', outsideClickHandler);

    // Make sure that selected refinements/filters are shown first
    // const sortedItems = orderBy(items, 'label', 'asc');
    const sortedItems = orderBy(items, `_source.${attribute}`, 'asc');
    const selectedItems = sortedItems.filter((item) => item.isRefined);
    // const unSelectedItems = sortedItems.filter((item) => !item.isRefined);
    //
    // All of the items are unSelected it seems
    const unSelectedItems = sortedItems.filter(
      (item) => item._source[attribute] !== 'none'
    );

    setItemState([...selectedItems, ...unSelectedItems]);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', outsideClickHandler);
    };
  }, [items, attribute]);

  return (
    <div>
      <button
        type="button"
        className={classNames('RefinementList', {
          'RefinementList--open': open
        })}
        onClick={toggleList}
        ref={filterRef}
      >
        <p>
          {/* {attribute}: {currentRefinement.length} */}
          {attribute}
        </p>
        {open ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </button>
      {open ? (
        <div className="RefinementList__dropdown-container" ref={dropDownRef}>
          <div className="RefinementList__dropdown">
            <div className="RefinementList__search-wrapper">
              <input
                type="search"
                // onChange={filterSearch}
                className="RefinementList__search"
                placeholder="Search"
              />
            </div>
            <div className="RefinementList__search-results">
              {itemState.map((item) => (
                <button
                  type="button"
                  //   className={classNames('RefinementList__search-result', {
                  //     'RefinementList__search-result--selected': item.isRefined
                  //   })}
                  className={classNames(
                    'RefinementList__search-result',
                    'RefinementList__search-result--selected'
                  )}
                  onClick={(event) => addFilter(event, item)}
                  key={item._id}
                  // onClick={() => console.log(item._source[attribute])}
                  //   key={`${item.label}${item.count}`}
                >
                  <p>
                    {/* {item.label} ({item.count}) */}
                    {item._source[attribute]}
                  </p>
                  {/* {i === items.length - 1 ? null : (
                    <div className="RefinementList__separator" />
                  )} */}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ELKRefinementList;
