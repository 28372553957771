import React from 'react'
import styles from '../../../styles/housing/housing-details.module.css'
const ImageGallery = ({ rental }) => {
  return (
    <section id={styles.photo_section}>
      <section id={styles.gallery}>
        {rental.photos ? (
          rental.photos.map((photo, index) => {
            return (
              <img
                src={photo.MediaURL}
                key={index}
                alt="housing image"
                referrerPolicy="no-referrer"
              />
            )
          })
        ) : (
          <></>
        )}
      </section>
    </section>
  )
}

export default ImageGallery
