import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';

import styled from 'styled-components';
import { Button, Container } from 'shards-react';

const StyledProfilePage = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 1rem;
  }

  button {
    margin-bottom: 2rem;
  }
`;

const StyledEmailStatus = styled.span`
  color: ${(props) => (props.status === 'Verified' ? 'green' : 'red')};
`;

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin: 1rem 2rem;
  }
`;

const ProfilePage = () => {
  const [emailStatus, setEmailStatus] = useState('');
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);
  const history = useHistory();

  useEffect(() => {
    if (isLoaded(auth) && !isEmpty(auth)) {
      if (auth.emailVerified) {
        setEmailStatus('Verified');
      } else {
        setEmailStatus('Unverified');
      }
    }
  }, [auth]);

  const goToDashboard = () => {
    history.push('/');
  };

  const signOut = () => {
    firebase.logout();
  };

  return (
    <StyledProfilePage className="card">
      <h1>You&apos;re logged in!</h1>
      <Button onClick={signOut}>Sign out</Button>
      <h5>Email: {auth.email}</h5>
      <h5>
        Email verification status:{' '}
        <StyledEmailStatus status={emailStatus}>
          {emailStatus}
        </StyledEmailStatus>
      </h5>

      <StyledButtonGroup>
        <Button onClick={goToDashboard}>Go to Dashboard</Button>
        <Link to="/find">
          <Button>Update your profile info</Button>
        </Link>
      </StyledButtonGroup>
    </StyledProfilePage>
  );
};

export default ProfilePage;
