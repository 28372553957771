import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useSelector } from 'react-redux';

import { useFirestore } from 'react-redux-firebase';
import axios from 'axios';
import { Container } from 'shards-react';

import { buildAlgoliaQuery } from '../../utils/buildAlgoliaQuery';
import FindQuestionnaire from '../../components/forms/FindQuestionnaire';
// import ScholarshipSearch from '../../components/scholarships/ScholarshipSearch';
// import { SET_SEARCH_FILTERS } from '../../actions/types';
import './styles.scss';
import ELKComponent from '../../components/scholarships/v2/ELKComponent';
import Pagination from '../../components/file/Pagination';

const API_ENDPOINT =
  'https://qzctnj2ygj.execute-api.us-east-2.amazonaws.com/search-es-api-test/';

const Find = () => {
  const [showModal, setShowModal] = useState(false);
  const [scholarshipList, setScholarshipList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalScholarships, setTotalScholarships] = useState(0);

  // const searchFilter = useSelector((state) => state.find.searchFilter);
  const showMatchedOnly = useSelector((state) => state.find.showMatchedOnly);
  const uid = useSelector((state) => state.firebase.auth.uid);
  const firestore = useFirestore();
  // const dispatch = useDispatch();

  // const firestoreUsersRef = firestore.collection('users').doc(uid);
  // const firebase = useFirebase();
  // const db = firebase.firestore();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    // const elasticSearchQuery = showMatchedOnly ? searchFilter : '';
    const getScholarshipData = async () => {
      const firestoreForm = await firestore
        .collection('scholarships')
        .doc(uid)
        .get();
      if (firestoreForm.data() !== undefined) {
        const newFilterString = buildAlgoliaQuery(firestoreForm.data());
        // console.log(newFilterString);
        const response = await axios
          .post(API_ENDPOINT, {
            payload: {
              data: newFilterString,
              page: currentPage * 100
            }
          })
          .catch((error) => {
            // console.log('g');
            // eslint-disable-next-line
            console.log(error);
          });
        // console.log(response);
        // console.log(JSON.parse(response.data));
        //
        // console.log(uid);

        const firestoreUserForm = await firestore.collection('users').doc(uid);

        // console.log(firestoreUserForm.data());     change firestoreUserForm above to get data!! add get() *empty
        await firestoreUserForm.set(
          {
            total: JSON.parse(response.data).hits.total.value,
            sample: JSON.parse(response.data).hits.hits.slice(0, 4)
          },
          { merge: true }
        );

        setTotalScholarships(JSON.parse(response.data).hits.total.value);
        setScholarshipList(JSON.parse(response.data).hits.hits);
      }
    };
    getScholarshipData();
  }, [
    firestore,
    uid,
    showMatchedOnly,
    currentPage
    /* searchFilter, showMatchedOnly */
  ]);

  return (
    <>
      <div className="FindPage">
        <h1 className="FindPage__title">Scholarship Matches</h1>
        <h5>
          Fill out the questionnaire below to get matched for scholarships!
        </h5>
        <Container className="FindPage__button-row">
          <button
            className="FindPage__button"
            onClick={() => setShowModal(!showModal)}
            type="button"
          >
            Questionnaire
          </button>
        </Container>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalScholarships={totalScholarships}
        />
        <Container>
          {/* {scholarshipList ? (
            <ScholarshipSearch
              showMatchedOnly={showMatchedOnly}
              scholarships={scholarshipList}
            />
          ) : (
            <p>Loading scholarships...</p>
          )} */}

          {scholarshipList ? (
            // <ELKComponent showMatchedOnly={showMatchedOnly} />
            <>
              <ELKComponent
                hits={scholarshipList}
                showMatchedOnly={showMatchedOnly}
              />
            </>
          ) : (
            <p>Loading scholarships...</p>
          )}
        </Container>
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalScholarships={totalScholarships}
        />
      </div>
      {showModal ? (
        <FindQuestionnaire open={showModal} toggleModal={toggleModal} />
      ) : null}
    </>
  );
};

export default Find;
