// Returns an object of requirements as keys with true/false values
// depending on whether or not the password meets the requirement
export const validatePassword = (password) => {
  return {
    passwordLength: password.length > 5,
    upperCase: password.search(/[A-Z]/) > -1,
    number: password.search(/[0-9]/) > -1,
    specialChar: password.search(/[! @ # $ % ^ & * - + = _ , : ; .]/) > -1
  };
};
