import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import fileReducer from './fileReducer';
import findReducer from './findReducer';

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  file: fileReducer,
  find: findReducer
});
