import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import FormFieldError from './FormFieldError';

const DropDownSelect = ({ label, name, options }) => {
  return (
    <StyledDropDown>
      <label>
        <h6>{label}</h6>
      </label>
      <StyledSelectInput component="select" name={name}>
        <option value="" key="">
          Select
        </option>
        {options.map((option) => (
          <option value={option.value} key={option.label}>
            {option.label}
          </option>
        ))}
      </StyledSelectInput>
      <FormFieldError name={name} />
    </StyledDropDown>
  );
};

const StyledDropDown = styled.div`
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledSelectInput = styled(Field)`
  height: 3rem;
  border: solid 2px #dee2e6;
`;

export default DropDownSelect;
