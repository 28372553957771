/* eslint no-underscore-dangle: 0 */
// Algolia API results use underscore variables
import React, { useState } from 'react';
// import { Highlight, Snippet } from 'react-instantsearch-dom';

import AwardComponent from '../../ScholarshipCard/AwardComponent';
import BadgeRow from '../../ScholarshipCard/BadgeRow';
import { displayedAttributes } from '../../ScholarshipCard/displayedAttributes';
import ScholarshipModal from '../../ScholarshipCard/ScholarshipModal';
import '../../ScholarshipCard/styles.scss';

const ELKCard = ({ hit }) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <button
        type="button"
        className="ScholarshipCard"
        onClick={toggle}
        onKeyDown={toggle}
      >
        <h4>
          <p>{hit._source.Name}</p>
        </h4>
        <div className="ScholarshipCard__award-deadline">
          <div className="ScholarshipCard__badge">
            <AwardComponent
              avgAward={hit._source.AverageAward}
              minAward={hit._source.MinAward}
              maxAward={hit._source.MaxAward}
            />
          </div>
          <div className="ScholarshipCard__deadline">
            {hit._source.Deadline}
          </div>
        </div>
        <div className="ScholarshipCard__desc">
          <div>{hit._source.Description}</div>
        </div>
        <div className="ScholarshipCard__bottom-row">
          <BadgeRow
            displayedAttributes={displayedAttributes}
            hit={hit}
            limit={3}
            hideMulti
          />
          <a href={hit._source.Url} target="blank">
            <div className="ScholarshipCard__apply">Apply</div>
          </a>
        </div>
      </button>

      <ScholarshipModal open={open} toggle={toggle} hit={hit} />
    </>
  );
};

export default ELKCard;
