import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import styled from 'styled-components';
import {
  Alert,
  Button,
  FormInput,
  Modal,
  ModalHeader,
  ModalBody
} from 'shards-react';

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    color: var(--primary-color);
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h6 {
    max-width: 600px;
    text-align: center;
    margin-bottom: 1rem;
  }

  input {
    margin-bottom: 1rem;
  }
`;

const ForgotPasswordModal = ({ open, toggleModal }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const firebase = useFirebase();

  const resetPassword = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(setStatus('success'))
      .catch((error) => {
        setStatus('error');
        setErrorMsg(error);
      });
  };

  const keyPressHandler = (event) => {
    // Prevent the enter key from refreshing the webapp
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Modal open={open} toggle={toggleModal}>
      <StyledModalHeader>Forgot your password?</StyledModalHeader>
      <StyledModalBody>
        {status === 'success' ? (
          <Alert theme="primary">Email sent!</Alert>
        ) : null}
        {status === 'error' ? (
          <Alert theme="danger">Error: {errorMsg}</Alert>
        ) : null}
        <h6>Enter your email below to reset your password.</h6>
        <FormInput
          type="text"
          value={email}
          placeholder="example@email.com"
          onChange={(event) => setEmail(event.target.value)}
          onKeyPress={keyPressHandler}
        />
        <Button pill outline className="pill-button" onClick={resetPassword}>
          Reset password
        </Button>
      </StyledModalBody>
    </Modal>
  );
};

export default ForgotPasswordModal;
