import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import ResultsSearchBar from '../../components/housing/results/ResultsSearchBar'
import HousingCards from '../../components/housing/results/HousingCards'
import HousingMap from '../../components/housing/results/HousingMap'

import { ReactComponent as ListIcon } from '../../assets/housing/list-icon.svg'
import { ReactComponent as MapIcon } from '../../assets/housing/map-icon.svg'

import styles from '../../styles/housing/housing-results.module.css'

export const getSqft = (rooms) => {
  let sqMeters = 0
  if (rooms == undefined) return null
  rooms.forEach((room) => {
    sqMeters += room.RoomLength * room.RoomWidth
  })
  return Math.round(sqMeters * 3.281)
}

const HousingResults = () => {
  const { search } = useParams()
  const history = useHistory()
  const [width, setWidth] = useState(0)

  const [onList, setOnList] = useState(true)

  const [rentals, setRentals] = useState([])

  const clickDetail = (id) => {
    history.push({
      pathname: `/housing-details/${id}`,
      state: {
        lastPath: `${history.location.pathname}`
      }
    })
  }

  const fetchRentals = () => {
    fetch(
      'https://api.mlsgrid.com/v2/Property?$filter=OriginatingSystemName%20eq%20%27maris%27%20and%20MlgCanView%20eq%20true%20and%20PropertyType+eq+Enums.PropertyType%27ResidentialLease%27%20and%20StandardStatus+eq+Enums.StandardStatus%27Active%27&$expand=Media,Rooms,UnitTypes&$count=true',
      {
        method: 'GET',
        headers: {
          Authorization: process.env.REACT_APP_MLS_GRID_AUTH
        }
      }
    )
      .then((resp) => resp.json())
      .then((json) => {
        setRentals(
          json.value
            .map((rental) => {
              return {
                lat: rental['Latitude'],
                lng: rental['Longitude'],
                price_min: rental['ListPrice'],
                address: rental['StreetNumber'] + ' ' + rental['StreetName'],
                StreetSuffix: rental['StreetSuffix']
                  ? rental['StreetSuffix']
                  : undefined,
                beds_max: rental['BedroomsTotal'] ? rental['BedroomsTotal'] : 0, // beds not consistent
                baths_max: rental['BathroomsTotalInteger']
                  ? rental['BathroomsTotalInteger']
                  : 0, // baths not consistent
                link: rental['ListPrice'], // no direct link to listing
                photos: rental['Media'],
                OriginatingSystemModificationTimestamp:
                  rental['OriginatingSystemModificationTimestamp'],
                CountyOrParish: rental['CountyOrParish'],
                City: rental['City'],
                PostalCode: rental['PostalCode'],
                Garage: rental['GarageSpaces'],
                Rooms: rental['RoomsTotal'],
                AgentName:
                  rental['ListAgentFirstName'] +
                  ' ' +
                  rental['ListAgentLastName'],
                ListOfficePhone: rental['ListOfficePhone'],
                SquareFeet: rental['AboveGradeFinishedArea'] ?? undefined,
                StateOrProvince: rental['StateOrProvince'],
                ListingId: rental['ListingId']
              }
            })
            .filter((rental) => {
              // filter out rentals that don't have a lat/lng
              return rental.lat && rental.lng
            })
        )
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const getWidth = () => {
    return window.innerWidth
  }

  useEffect(() => {
    fetchRentals()
    setWidth(window.innerWidth)
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
  }, [])

  if (width == 0) return <></>

  return (
    <main>
      <ResultsSearchBar pastSearch={search}></ResultsSearchBar>
      {width > 768 ? (
        <section id={styles.results_section}>
          <HousingCards
            rentals={rentals}
            clickDetail={clickDetail}
          ></HousingCards>
          <HousingMap rentals={rentals} clickDetail={clickDetail}></HousingMap>
        </section>
      ) : (
        <section id={styles.results_section}>
          {onList ? (
            <HousingCards
              rentals={rentals}
              clickDetail={clickDetail}
            ></HousingCards>
          ) : (
            <HousingMap
              rentals={rentals}
              clickDetail={clickDetail}
            ></HousingMap>
          )}
          <div onClick={() => setOnList(!onList)} id={styles.results_toggle}>
            {onList ? (
              <div id={styles.toggle_contents}>
                <MapIcon></MapIcon> map
              </div>
            ) : (
              <div id={styles.toggle_contents}>
                <ListIcon></ListIcon> list
              </div>
            )}
          </div>
        </section>
      )}
    </main>
  )
}

export default HousingResults
