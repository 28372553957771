const isNull = (value) => {
  if (value === '' || value.length === 0 || value === 0) {
    return true;
  }
  return false;
};

// Converts date of birth to Age
function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

// Maps the form keys to the actual Algolia attribute name
const algoliaKeyMap = {
  gender: 'Gender',
  race: 'Race',
  gpa: 'GPA',
  gradeLevel: 'EnrollmentLevel',
  state: 'State',
  country: 'Country',
  actScore: 'ACT',
  satScore: 'SAT',
  career: 'CareerInterest',
  religion: 'Religion',
  major: 'Major',
  clubs: 'Club',
  military: 'MilitaryAffiliation',
  corporation: 'CorpAffiliation',
  sports: 'Sport',
  organization: 'Organization'
};

/**
 * Used to build out an individual filter when given the key of a form
 * question and the actual value of that question
 * @param {String} key
 * @param {String|String[|Number} value
 * @returns {String}
 */
const buildSingleFilter = (key, value) => {
  let filter = '';

  if (key === 'dateBirth') {
    if (!isNull(value)) {
      const formattedDate = value.replaceAll('-', '/');
      const studentAge = getAge(formattedDate);
      filter = `(MinAge <= ${studentAge}) AND (MaxAge >= ${studentAge})`;
    } else {
      filter = `(MinAge <= ${0})`;
    }
  } else if (typeof value === 'number') {
    const algoliaAttribute = algoliaKeyMap[key];
    const finalValue = isNull(value) ? 0 : value;
    filter = `(${algoliaAttribute} <= ${finalValue} OR ${algoliaAttribute} = 0)`;
  } else if (Array.isArray(value)) {
    // Multi-valued attributes need a bunch of OR statements to support
    // multiple values, since we can only filter one value at a time
    const algoliaAttribute = algoliaKeyMap[key];
    const finalValueList = [`${algoliaAttribute}: none`];
    if (!isNull(value)) {
      value.forEach((entry) => {
        let entryValue = entry.value;
        // '/' is a reserved character in Elasticsearch
        // Use backslash as an escaping character
        if (entryValue.includes('/')) {
          entryValue = entryValue.replace('/', '\\/');
        }
        finalValueList.push(`${algoliaAttribute}:${entryValue}`);
      });
    }
    filter = `(${finalValueList.join(' OR ')})`;
  } else {
    const algoliaAttribute = algoliaKeyMap[key];
    const finalValue = isNull(value) ? 'none' : value;
    filter = `(${algoliaAttribute}:${finalValue} OR ${algoliaAttribute}:none)`;
  }

  return filter;
};

// Comment/uncomment lines as needed to enable/disable filtering for a
// particular algolia attribute
const enabledQuestions = [
  // 'dateBirth',
  'gender',
  'race',
  'gpa',
  'gradeLevel',
  'state',
  'country',
  'actScore',
  'satScore',
  'career',
  'religion',
  'major',
  'clubs',
  'military',
  'corporation',
  'sports',
  'organization'
];

/**
 * buildAlgoliaQuery() is a function that takes in the Find scholarship
 * questionnaire form data and generates a query string that gets passed
 * into Algolia's search API.
 *
 * https://www.algolia.com/doc/api-reference/api-parameters/filters/?client=javascript
 */
function buildAlgoliaQuery(questions) {
  const filters = [];
  Object.entries(questions).forEach(([key, value]) => {
    if (enabledQuestions.includes(key)) {
      filters.push(buildSingleFilter(key, value));
    }
  });
  // Combine filters into a string
  const algoliaFilteredQuery = filters.join(' AND ');
  return algoliaFilteredQuery;
}

export { buildAlgoliaQuery };
