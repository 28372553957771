import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import searchIcon from '../../../assets/housing/search-icon.svg'
import styles from '../../../styles/housing/results-search-bar.module.css'

const ResultsSearchBar = ({ pastSearch }) => {
  const history = useHistory()
  const [search, setSearch] = useState('')

  const handleChange = (event) => {
    setSearch(event.target.value)
    console.log(event.target.value)
  }

  const handleSubmit = (event) => {
    history.push(`/housing/${search}`)
    event.preventDefault()
  }
  return (
    <section id={styles.query_box}>
      <form onSubmit={handleSubmit}>
        <div id={styles.search_bar}>
          <input
            placeholder={`${pastSearch}`}
            value={search}
            onChange={handleChange}
            name="search"
            autoComplete="off"
            required
          />
          <button type="submit">
            <img src={searchIcon} alt="search icon" />
          </button>
        </div>
      </form>
      <h2 id={styles.filter}>filter</h2>
    </section>
  )
}

export default ResultsSearchBar
