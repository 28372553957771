import React from 'react'
import styles from '../../../styles/housing/search-partner-section.module.css'

const PartnerSection = ({ img, name, header, description, link, active }) => {
  return (
    <section>
      <h2 className={styles.partner_header}>{header}</h2>
      <img src={img} alt={name} className={styles.partner_image} />
      <p className={styles.partner_description}>{description}</p>
      <a
        href={link}
        className={active ? styles.partner_cta : styles.inactive_partner_cta}
        target="_blank"
        rel="noreferrer"
      >
        {active ? 'Learn More' : 'Coming Soon'}
      </a>
    </section>
  )
}

export default PartnerSection
