/* eslint-disable */
// Remove eslint disable once util function is used again
// FIXME: Prevent crashes when conditional question can't be found
const conditionalDict = [
  'academic__secondary',
  'family__married',
  'family__dependents',
  'family__dependents_other',
  'personal__military-served',
  'finance__taxes_has-return'
];

const conditionalChildren = [
  'academic__secondary-name',
  'family__married-current',
  'family__married-since',
  'family__married-spouse-attending',
  'family__married-divorced-date',
  'family__married-separated-date',
  'family__dependents_children',
  'family__dependents_children-count',
  'family__dependents_children-college',
  'family__dependents_other-count',
  'family__dependents_other-college',
  'personal__military-verteran',
  'family__support-fostered',
  'family__support',
  'family__support-adopted',
  'family__support-situations',
  'finance__taxes-upload',
  'finance__taxes_earned-money',
  'finance__taxes_earned-amount'
];

const getAcademicSeconary = (question, allQuestions) => {
  if (question.response.answer_name === 'secondary') {
    return allQuestions.find(
      (question) => question.name === 'academic__secondary-name'
    );
  }
  return [];
};

const getFamilyMarried = (question, allQuestions) => {
  if (question.response.answer === true) {
    const conditionalQuestions = [];

    const maritalStatus = allQuestions.find(
      (q) => q.name === 'family__married-current'
    );
    conditionalQuestions.push(maritalStatus);

    switch (maritalStatus.response.answer_name) {
      case 'married':
        const marriedSince = allQuestions.find(
          (q) => q.name === 'family__married-since'
        );
        const marriedSpouseAttending = allQuestions.find(
          (q) => q.name === 'family__married-spouse-attending'
        );
        conditionalQuestions.push(marriedSince, marriedSpouseAttending);
        break;
      case 'divorced':
        conditionalQuestions.push(
          allQuestions.find((q) => q.name === 'family__married-divorced-date')
        );
        break;
      case 'separated':
        conditionalQuestions.push(
          allQuestions.find((q) => q.name === 'family__married-separated-date')
        );
        break;
      default:
        break;
    }

    return conditionalQuestions;
  }
  return [];
};

const getFamilyDependents = (question, allQuestions) => {
  if (question.response.answer === true) {
    const returnQuestions = [];
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'family__dependents_children-count')
    );
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'family__dependents_children-college')
    );
    return returnQuestions;
  }
  return [];
};

const getFamilyDependentsOther = (questions, allQuestions) => {
  if (questions.response.answer === true) {
    const returnQuestions = [];
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'family__dependents_other-count')
    );
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'family__dependents_other-college')
    );
    return returnQuestions;
  }
  return [];
};

const getMilitaryServed = (questions, allQuestions) => {
  if (questions.response.answer === true) {
    const returnQuestions = [];
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'personal__military-verteran')
    );
    returnQuestions.push(
      allQuestions.find((q) => q.name === 'family__support-fostered')
    );

    return returnQuestions;
  }
  if (questions.response.answer === false) {
    const returnQuestions = [];
    const familySupport = allQuestions.find(
      (q) => q.name === 'family__support'
    );
    returnQuestions.push(familySupport);

    if (familySupport.response.answer === false) {
      const familySupportAdopted = allQuestions.find(
        (q) => q.name === 'family__support-adopted'
      );
      const familySupportSituatons = allQuestions.find(
        (q) => q.name === 'family__support-situations'
      );
      returnQuestions.push(familySupportAdopted, familySupportSituatons);
    }

    return returnQuestions;
  }
  return [];
};

const getFinanceTaxes = (question, allQuestions) => {
  if (question.response.answer === false) {
    const returnQuestion = [];
    const taxesEarned = allQuestions.find(
      (q) => q.name === 'finance__taxes_earned-money'
    );
    returnQuestion.push(taxesEarned);

    if (taxesEarned.response.answer === true) {
      returnQuestion.push(
        allQuestions.find((q) => q.name === 'finance__taxes_earned-amount')
      );
    }
    return returnQuestion;
  }
  return [];
};

const getConditionalQuestions = (question, allQuestions) => {
  let returnQuestions = [];
  switch (question.name) {
    case 'academic__secondary':
      returnQuestions = returnQuestions.concat(
        getAcademicSeconary(question, allQuestions)
      );
      break;
    case 'family__married':
      returnQuestions = returnQuestions.concat(
        getFamilyMarried(question, allQuestions)
      );
      break;
    case 'family__dependents':
      returnQuestions = returnQuestions.concat(
        getFamilyDependents(question, allQuestions)
      );
      break;
    case 'family__dependents_other':
      returnQuestions = returnQuestions.concat(
        getFamilyDependentsOther(question, allQuestions)
      );
      break;
    case 'personal__military-served':
      returnQuestions = returnQuestions.concat(
        getMilitaryServed(question, allQuestions)
      );
      break;
    case 'finance__taxes_has-return':
      returnQuestions = returnQuestions.concat(
        getFinanceTaxes(question, allQuestions)
      );
      break;
    default:
      break;
  }

  return returnQuestions;
};

// Takes array, returns object
const createFilePages = (questions) => {
  const pages = {};
  let questionQueue = 0;
  let currentPage = 0;

  for (const question of questions) {
    if (questionQueue === 5) {
      currentPage += 1;
      questionQueue = 0;
    }

    let questionsToAdd = [];

    // Generate conditional questions for conditional parents
    // or simply append normal questions to the page
    if (conditionalDict.includes(question.name)) {
      questionsToAdd = [question];
      questionsToAdd = questionsToAdd.concat(
        getConditionalQuestions(question, questions)
      );
      questionQueue += 1;
    } else if (!conditionalChildren.includes(question.name)) {
      questionsToAdd = [question];
      questionQueue += 1;
    }

    if (currentPage in pages) {
      pages[currentPage] = pages[currentPage].concat(questionsToAdd);
    } else {
      pages[currentPage] = questionsToAdd;
    }
  }

  return pages;
};

export { createFilePages };
