import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const StyledBackdrop = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  touch-action: none;
`;

const StyledModalContainer = styled.div`
  /* 100vh is bigger than the actual viewport on mobile devices
  Hence we use window.innerHeight */
  height: ${window.innerHeight}px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: none;
`;

const StyledModalChild = styled.div`
  position: relative;
  max-height: 80vh;
  max-width: 80vw;
  z-index: 9000;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0 7px 35px rgba(#5a6169, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* On mobile, expand the modal to a page-like view due to prevent
  weird scrolling issues */
  @media (max-width: 800px) {
    height: ${window.innerHeight}px;
    width: ${window.innerWidth}px;
    max-height: ${window.innerHeight}px;
    max-width: ${window.innerWidth}px;
    border-radius: 0;
  }
`;

const StyledModalCloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 2rem;
  cursor: pointer;
`;

const Modal = React.forwardRef(({ children, open, toggle }, ref) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const backdropRef = useRef(null);

  const closeModal = () => {
    toggle();
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      // When closing the modal, restore the default styles on the root
      // element to get back page scrolling
      const root = document.documentElement;
      const originalScrollY = root.style.top;
      root.style.position = '';
      root.style.top = '';
      root.style.touchAction = 'pan-y';
      window.scrollTo(0, parseInt(originalScrollY, 10) * -1);
    };
  }, [open]);

  // If the modal is open, set the root to a fixed position to prevent
  // the background from scrolling
  if (open) {
    const root = document.documentElement;
    root.style.position = 'fixed';
    root.style.top = `-${scrollPosition}px`;
    root.style.width = '100vw';
    root.style.touchAction = 'none';
  }

  return (
    <>
      {open ? (
        <StyledModalContainer>
          <StyledBackdrop ref={backdropRef} onClick={closeModal} />
          <StyledModalChild ref={ref || null}>
            <StyledModalCloseIcon icon={faTimes} onClick={closeModal} />
            {children}
          </StyledModalChild>
        </StyledModalContainer>
      ) : null}
    </>
  );
});

export default Modal;
