import React, { useRef, useState, useEffect } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import styles from '../../../styles/housing/housing-map.module.css'

import mapboxgl from '!mapbox-gl'

// mapbox setup

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
const DetailMap = ({ rental }) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const lng = -90.1994
  const lat = 38.627
  const zoom = 14

  const [renderedMarkers, setRenderedMarkers] = useState(false)

  useEffect(() => {
    if (!map.current) {
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [lng, lat],
        zoom: zoom,
        interactive: false
      })
      map.current.addControl(new mapboxgl.NavigationControl())
    }
    console.log(rental)

    if (!renderedMarkers && map.current && rental.lng && rental.lat) {
      setRenderedMarkers(true)
      map.current.flyTo({
        center: [rental.lng, rental.lat],
        essential: false // this animation is considered essential with respect to prefers-reduced-motion
      })
      // add marker
      if (rental.lng !== undefined && rental.lat !== undefined) {
        const markerElement = document.createElement('div')
        markerElement.className = styles.marker

        new mapboxgl.Marker(markerElement)
          .setLngLat([rental.lng, rental.lat])
          .addTo(map.current)
      }
    }
  })
  return <div ref={mapContainer} className={styles.map_container}></div>
}

export default DetailMap
