import React from 'react'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

// Redux store import
import store from './store'

// Custom Auth component imports
import PrivateRoute from './routes/PrivateRoute'
import AuthLoading from './components/auth/AuthLoading'
import SignUpPage from './pages/SignUpPage'
import SignInPage from './pages/SignInPage'
import ProfilePage from './pages/ProfilePage'

// Main application pages
import Dashboard from './pages/Dashboard'
import File from './pages/File'
import Find from './pages/Find'
import NoPage404 from './pages/NoPage404'
import FeedbackForm from './pages/Feedback'
import { HousingSearch, HousingResults, HousingDetail } from './pages/Housing/'

// Layout components and CSS
import Header from './components/layout/Header'

// Generic Error Bounadry fallback UI
import GenericErrorBoundary from './components/error/GenericErrorBoundary'

// React-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL
})

firebase.firestore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <AuthLoading>
            <Header />
            <Switch>
              <PrivateRoute exact path="/">
                <GenericErrorBoundary>
                  <Dashboard />
                </GenericErrorBoundary>
              </PrivateRoute>
              <PrivateRoute path="/file">
                <GenericErrorBoundary>
                  <File />
                </GenericErrorBoundary>
              </PrivateRoute>
              <PrivateRoute path="/fund">
                <GenericErrorBoundary>
                  <Find></Find>
                </GenericErrorBoundary>
              </PrivateRoute>
              <Route path="/housing-details/:id">
                <HousingDetail></HousingDetail>
              </Route>
              <Route path="/housing/:search">
                <HousingResults></HousingResults>
              </Route>
              <Route path="/housing">
                <HousingSearch></HousingSearch>
              </Route>
              <Route path="/find">
                <Redirect to="/housing" />
              </Route>
              <Route path="/signup" component={SignUpPage} />
              <Route path={['/signin', '/login']} component={SignInPage} />
              <PrivateRoute path="/feedback" component={FeedbackForm}>
                <GenericErrorBoundary>
                  <Find />
                </GenericErrorBoundary>
              </PrivateRoute>
              <PrivateRoute path="/profile">
                <GenericErrorBoundary>
                  <ProfilePage />
                </GenericErrorBoundary>
              </PrivateRoute>
              <Route component={NoPage404} />
            </Switch>
          </AuthLoading>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

export default App
