import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import styled from 'styled-components';
import { Alert, Modal, ModalHeader, ModalBody } from 'shards-react';

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
  align-items: center;

  h5 {
    color: var(--primary-color);
    font-family: 'Poppins', 'Roboto', sans-serif;
    font-weight: 700;
    text-align: center;
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h6 {
    max-width: 600px;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const StyledTextButton = styled.button`
  border: none;
  background: none;
  color: var(--primary-color);
  font-weight: 700;

  &:hover {
    color: var(--secondary-color);
  }
`;

const EmailVerified = ({ auth, children }) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const firebase = useFirebase();

  const toggleModal = () => {
    setOpen(!open);
  };

  const verifyEmail = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(setStatus('success'))
      .catch((error) => {
        setStatus('error');
        setErrorMsg(error);
      });
  };

  // Don't worry about email verification in a dev environment
  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>;
  }

  return (
    <>
      {auth.emailVerified ? null : (
        <Modal open={open} toggle={toggleModal}>
          <StyledModalHeader>You should verify your email!</StyledModalHeader>
          <StyledModalBody>
            {status === 'success' ? (
              <Alert theme="primary">Email Sent!</Alert>
            ) : null}
            {status === 'error' ? (
              <Alert theme="danger">Error: {errorMsg}</Alert>
            ) : null}
            <h6>
              We need to make sure your email is correct because we will be
              sending important information to you! Please click the link below
              to send a verification email, and check your inbox / spam folder
              for the verification email!
            </h6>
            <h6>Once you&apos;re done, just refresh this page!</h6>
            <StyledTextButton onClick={verifyEmail}>
              Click here to send a verification email!
            </StyledTextButton>
          </StyledModalBody>
        </Modal>
      )}
      {children}
    </>
  );
};

export default EmailVerified;
